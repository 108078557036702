import React, { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Rectangle from './rectangle/rectangle';
import { hazardAndRiskDefaultStyle, palettes } from '../../../environment/environment';
import LoadingPreview from './loading-preview';
import userSelectors from '../../../modules/user/userSelectors';
import recordSelectors from '../../../modules/diagram/diagramSelectors';
import { useAppDispatch, useAppSelector } from '../../../modules/hooks';
import { DiagramStatus } from './hazard';
import RecordServiceJS from '../../../modules/diagram/diagramService';
import diagramActions from '../../../modules/diagram/diagramActions';
import { useLocation } from 'react-router-dom';
import { BowtieStateData } from '../../../services/bowtie-data-types';
import { useAIContext } from '../../../context/ai.context';

interface RiskScenarioProps {
  mode: DiagramStatus;
  isLoading: boolean;
  defaultRiskScenario?: string;
  hasCircleLeft?: boolean;
  hasCircleRight?: boolean;
  link?: string;
  showHazard: () => void;
  hazardInputRef: React.RefObject<HTMLTextAreaElement>;
  bowtieData?: BowtieStateData;
}

const recordService = new RecordServiceJS('test');

const RiskScenario = (
  {
    defaultRiskScenario,
    isLoading = false,
    hasCircleLeft = false,
    hasCircleRight = false,
    link,
    mode,
    showHazard,
    hazardInputRef,
    bowtieData,
  }: RiskScenarioProps,
  forwardRef: Ref<HTMLTextAreaElement>
) => {
  const [editRiskScenario, setEditRiskScenario] = useState(DiagramStatus.NEW === mode);
  const [isEditRiskScenarioDisabled, setIsEditRiskScenarioDisabled] = useState(false);
  const [riskScenario, setRiskScenario] = useState(defaultRiskScenario);
  const [riskScenarioTextAreaStyle, setRiskScenarioTextAreaStyle] = useState(hazardAndRiskDefaultStyle);
  const isAIGenerated = useAIContext();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  useImperativeHandle(forwardRef, () => inputRef.current as HTMLTextAreaElement);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);
  const isRiskScenarioLoading = useAppSelector(recordSelectors.selectAddLoading);

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const canEditRiskScenario =
    userWithPermissions &&
    userWithPermissions.permissions &&
    userWithPermissions.permissions.editRecord &&
    userWithPermissions.permissions.editRecord.main;

  useEffect(() => {
    if (inputRef.current?.focus && editRiskScenario) {
      inputRef.current.focus();
    }
  }, [editRiskScenario]);

  const handleRectangleClick = () => {
    if (canEditRiskScenario) {
      setRiskScenarioTextAreaStyle((oldStyle) => {
        return {
          ...oldStyle,
          color: '#333',
          borderStyle: 'dashed',
          borderColor: palettes.criticalControl.primary,
        };
      });

      setEditRiskScenario(true);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      if (inputRef.current?.value === '') {
        inputRef.current.focus();
      } else {
        inputRef.current?.blur();
      }
    }
  };

  const handleAddRiskScenario = () => {
    queryParams.delete('formId');
    queryParams.delete('moduleId');

    const urlParsed = queryParams.toString();

    if (inputRef.current && inputRef.current.value) {
      setRiskScenarioTextAreaStyle((oldStyle) => {
        oldStyle.borderStyle = 'solid';
        oldStyle.color = 'rgba(51, 51, 51, 0.5)';
        oldStyle.borderColor = palettes.criticalControl.rgb[5];

        return oldStyle;
      });
      dispatch(
        diagramActions.doAddRiskScenario({
          payload: inputRef.current.value,
          urlParsed,
          hazardInputRef,
        })
      );

      showHazard();
    }
  };

  const handleUpdateRiskScenario = async () => {
    if (inputRef.current && inputRef.current.value !== '') {
      setIsEditRiskScenarioDisabled(true);

      if (inputRef.current?.value !== riskScenario) {
        const value = inputRef.current.value.trim();

        setRiskScenarioTextAreaStyle((oldStyle) => {
          oldStyle.borderStyle = 'solid';
          oldStyle.color = 'rgba(51, 51, 51, 0.5)';
          oldStyle.borderColor = palettes.criticalControl.rgb[5];

          return oldStyle;
        });

        if (isAIGenerated === false) {
          await recordService.updateRiskScenarioField(bowtieData, value);
        }

        setRiskScenario(value);
        setEditRiskScenario(false);
        setIsEditRiskScenarioDisabled(false);
        dispatch(diagramActions.doUpdateRiskScenarioValue(value));

        if (hazardInputRef?.current?.value === '') {
          hazardInputRef?.current?.focus();
        }
      } else {
        setEditRiskScenario(false);
        setIsEditRiskScenarioDisabled(false);
        setRiskScenarioTextAreaStyle((oldStyle) => {
          oldStyle.color = '#333';
          oldStyle.borderStyle = 'dashed';
          oldStyle.borderColor = palettes.criticalControl.primary;

          return oldStyle;
        });
      }
    } else {
      setEditRiskScenario(false);
      setIsEditRiskScenarioDisabled(false);
    }
  };

  // resolve values based on new/edit mode
  const handleOnBlur = DiagramStatus.NEW === mode ? handleAddRiskScenario : handleUpdateRiskScenario;
  const textAreaDisabled = DiagramStatus.NEW === mode ? isRiskScenarioLoading : isEditRiskScenarioDisabled;

  // element
  if (isLoading) {
    return (
      <div
        style={{
          ...riskScenarioTextAreaStyle,
          marginBottom: '8rem',
          resize: 'none',
          flexDirection: 'column',
        }}
      >
        <LoadingPreview message="Risk will be displayed here" className="hazard-risk" />
      </div>
    );
  } else if (riskScenario && !editRiskScenario) {
    return (
      <Rectangle
        text={riskScenario}
        color={riskScenario ? '#ffffff' : '#333333'}
        backgroundColor={palettes.criticalControl.primary}
        borderColor={palettes.criticalControl.primary}
        borderWidth="2px"
        width="200px"
        fontSize="20px"
        id="risk-scenario"
        hasCircleRight={hasCircleLeft}
        hasCircleLeft={hasCircleRight}
        link={link}
        click={handleRectangleClick}
      />
    );
  } else {
    return (
      <textarea
        rows={2}
        id="risk-scenario"
        defaultValue={defaultRiskScenario}
        ref={inputRef}
        disabled={textAreaDisabled}
        onKeyDown={handleOnKeyDown}
        placeholder="Click to add Risk Scenario"
        onBlur={handleOnBlur}
        style={{
          ...riskScenarioTextAreaStyle,
          marginBottom: '8rem',
          resize: 'none',
          width: '200px',
        }}
      />
    );
  }
};

export default forwardRef(RiskScenario);
