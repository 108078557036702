import { AuthProvider, UserProvider } from '@myosh/myosh-login';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesComponent from './components/routing/RoutesComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <UserProvider>
          <RoutesComponent />
        </UserProvider>
      </AuthProvider>
      <ToastContainer position="bottom-right" />
    </Router>
  );
}

export default App;
