import React from 'react';
import GeneralContainer from '../general-container/general-container';
import { palettes } from '../../../../environment/environment';
import MultiLineContainer from '../multi-line-container/multi-line-container';
import { BowtieStateData } from '../../../../services/bowtie-data-types';

interface ConsequencesExistingProps {
  bowtieData: BowtieStateData;
}

const ConsequencesExisting = ({ bowtieData }: ConsequencesExistingProps) => {
  return (
    <GeneralContainer
      title="Consequences"
      id="consequences-container"
      backgroundColor={palettes.lightBlue.primary}
      bowtieData={bowtieData}
    >
      <MultiLineContainer
        mode="single"
        id="consequences"
        lines={bowtieData.consequences}
        controlLines={bowtieData.consequences}
        form={bowtieData.bowtieConfiguration.forms.consequences}
        alignment="right"
        backgroundColor={palettes.lightBlue.primary}
      />
    </GeneralContainer>
  );
};

export default ConsequencesExisting;
