import React from 'react';
import Controls from '../../../../views/shared/components/controls.component';
import LoadingPreview from '../loading-preview';

interface MitigatingControlsProps {
  isLoading: boolean;
  color: string;
}

const MitigatingControls = ({ isLoading, color }: MitigatingControlsProps) => {
  return (
    <Controls
      title={'Mitigating Controls'}
      id="mitigating-controls-container"
      content={
        isLoading ? (
          <LoadingPreview message="Mitigating controls will be displayed here" />
        ) : (
          'Mitigating controls will be displayed here'
        )
      }
      height={'75vh'}
      width={'20vw'}
      color={color}
    />
  );
};

export default MitigatingControls;
