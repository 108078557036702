import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import filterSelectors from '../../../../modules/filter/filterSelectors';
import userSelectors from '../../../../modules/user/userSelectors';
import { BowtieModuleForm } from '../../../../services/common-data-types';
import { BowtieBasicRecord, BowtieCauseRecord, BowtieConsequenceRecord } from '../../../../services/bowtie-data-types';
import diagramSelectors from '../../../../modules/diagram/diagramSelectors';
import diagramActions from '../../../../modules/diagram/diagramActions';
import { CONTAINER_ALIGNMENT } from './multi-line-container';
import TextArea from '../../../../views/shared/components/text-area.component';
import Rectangle, { LINE_DIRECTION } from '../rectangle/rectangle';
import { palettes } from '../../../../environment/environment';
import { ControlPayload } from '../../../../modules/diagram/controlActions';

// MultiElementLine component
export interface MultiElementLineType {
  id: string; // used as react element key only
  parentId: number;
  childControls: Array<BowtieConsequenceRecord> | Array<BowtieCauseRecord>;
}

interface MultiElementLineProps {
  multiLines: MultiElementLineType;
  alignment: string;
  id: string;
  index: number;
  form: BowtieModuleForm;
}

const MultiElementLine = ({ multiLines, alignment, id, index, form }: MultiElementLineProps) => {
  const [elementToEdit, setElementToEdit] = useState<number>();
  const dispatch = useAppDispatch();

  const criticalFilterValues = useAppSelector(filterSelectors.selectCriticalFilterValues);
  const nonEffectiveFilterValues = useAppSelector(filterSelectors.selectNonEffectiveFilterValues);
  const criticalFilter = useAppSelector(filterSelectors.selectCriticalFilter);
  const nonEffectiveFilter = useAppSelector(filterSelectors.selectNonEffectiveFilter);
  const userWithPermissions = useAppSelector(userSelectors.selectUser);
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);

  const lines = multiLines.childControls ?? [];

  const addElement = (payload: { id: string; value: string }) => {
    const obj = {
      ...payload,
      parentId: multiLines.parentId,
    };

    dispatch(
      diagramActions.doAddInQueue({
        payload: obj,
        type: id,
      })
    );
  };

  const removeElement = (payload: { id: string; parentId: number }) => {
    if (id === 'preventative_control') {
      dispatch(diagramActions.doRemovePreventativeControl(payload));
    } else {
      dispatch(diagramActions.doRemoveMitigatingControl(payload));
    }
  };

  return (
    <div className="multi-element-line-container" key={index}>
      {lines && lines.length <= 2 ? (
        <div
          className="multi-element-first-line"
          style={{
            height: '178px',
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'space-between',
            paddingBottom: '30px',
          }}
        >
          <div
            className="second-line-container"
            style={{
              display: 'flex',
              flexDirection: alignment === CONTAINER_ALIGNMENT.LEFT ? 'row' : 'row-reverse',
            }}
          >
            {lines &&
              lines.map((line: BowtieBasicRecord, i: number) => {
                if (!line.value || elementToEdit === line.id) {
                  return (
                    <TextArea
                      key={`${line.id}_${i}`}
                      index={i}
                      id={id}
                      elementId={line.id}
                      addElement={addElement}
                      removeElement={removeElement}
                      lineDirection={LINE_DIRECTION.TOP}
                      parentId={multiLines.parentId}
                      defaultValue={line.value}
                      line={line}
                      setElementToEdit={setElementToEdit}
                      form={form}
                    />
                  );
                }

                if (i + 1 === lines.length) {
                  return (
                    <div className="line-rectangular-container" key={i}>
                      <Rectangle
                        color="#333"
                        link={line.linkUrl}
                        text={line.value as string}
                        status={line.status}
                        borderWidth="2px"
                        width="120px"
                        fontSize="12px"
                        id={`final_${id}_${index + 1}`}
                        hasLine={true}
                        lineColor={palettes.criticalControl.primary}
                        lineDirection={LINE_DIRECTION.TOP}
                        containerBackgroundColor={'rgb(240, 240, 240)'}
                        rawElement={{ ...line, parentId: multiLines.parentId } as ControlPayload}
                        removeElement={removeElement}
                        {...getRectangularFilterStyling(
                          line,
                          criticalFilter,
                          nonEffectiveFilter,
                          criticalFilterValues,
                          nonEffectiveFilterValues
                        )}
                        form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                        click={() => {
                          if (
                            userWithPermissions &&
                            userWithPermissions.permissions &&
                            userWithPermissions.permissions.editRecord &&
                            userWithPermissions.permissions.editRecord.controls
                          ) {
                            setElementToEdit(line.id);
                          }
                        }}
                      />
                    </div>
                  );
                }

                return (
                  <div className="line-rectangular-container" id={line.value as string} key={i}>
                    <Rectangle
                      id={line.value}
                      color="#333"
                      link={line.linkUrl}
                      text={line.value as string}
                      status={line.status}
                      borderWidth="2px"
                      width="120px"
                      fontSize="12px"
                      hasLine={true}
                      lineColor={palettes.criticalControl.primary}
                      lineDirection={LINE_DIRECTION.TOP}
                      containerBackgroundColor={'rgb(240, 240, 240)'}
                      rawElement={{ ...line, parentId: multiLines.parentId } as ControlPayload}
                      removeElement={removeElement}
                      form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                      {...getRectangularFilterStyling(
                        line,
                        criticalFilter,
                        nonEffectiveFilter,
                        criticalFilterValues,
                        nonEffectiveFilterValues
                      )}
                      click={() => {
                        if (
                          userWithPermissions &&
                          userWithPermissions.permissions &&
                          userWithPermissions.permissions.editRecord &&
                          userWithPermissions.permissions.editRecord.controls
                        ) {
                          setElementToEdit(line.id);
                        }
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div
          style={{
            height: '178px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '30px',
          }}
          key={index}
        >
          <div
            className="multi-element-first-line"
            style={{
              display: 'flex',
              flexDirection: alignment === CONTAINER_ALIGNMENT.LEFT ? 'row' : 'row-reverse',
            }}
          >
            {lines.map((line: BowtieBasicRecord, i: number) => {
              if (i % 2 === 0) {
                if (!line.value || elementToEdit === line.id) {
                  return (
                    <TextArea
                      key={`${line.id}_${i}`}
                      index={i}
                      id={id}
                      elementId={line.id}
                      addElement={addElement}
                      removeElement={removeElement}
                      lineDirection={LINE_DIRECTION.BOTTOM}
                      parentId={multiLines.parentId}
                      defaultValue={line.value}
                      line={line}
                      setElementToEdit={setElementToEdit}
                      form={form}
                    />
                  );
                }
                if (i + 1 === lines.length) {
                  return (
                    <div className="line-rectangular-container" key={i}>
                      <Rectangle
                        color="#333"
                        link={line.linkUrl}
                        text={line.value as string}
                        borderWidth="2px"
                        status={line.status}
                        width="120px"
                        fontSize="12px"
                        id={`final_${id}_${index + 1}`}
                        hasLine={true}
                        lineColor={palettes.criticalControl.primary}
                        lineDirection={LINE_DIRECTION.BOTTOM}
                        editable={line.value === null ? true : false}
                        containerBackgroundColor={'rgb(240, 240, 240)'}
                        form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                        rawElement={{ ...line, parentId: multiLines.parentId } as ControlPayload}
                        removeElement={removeElement}
                        {...getRectangularFilterStyling(
                          line,
                          criticalFilter,
                          nonEffectiveFilter,
                          criticalFilterValues,
                          nonEffectiveFilterValues
                        )}
                        click={() => {
                          if (
                            userWithPermissions &&
                            userWithPermissions.permissions &&
                            userWithPermissions.permissions.editRecord &&
                            userWithPermissions.permissions.editRecord.controls
                          ) {
                            setElementToEdit(line.id);
                          }
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div className="line-rectangular-container" key={i} id={line.value}>
                    <Rectangle
                      color="#333"
                      link={line.linkUrl}
                      id={line.value}
                      text={line.value}
                      status={line.status}
                      borderWidth="2px"
                      width="120px"
                      fontSize="12px"
                      hasLine={true}
                      lineColor={palettes.criticalControl.primary}
                      lineDirection={LINE_DIRECTION.BOTTOM}
                      containerBackgroundColor={'rgb(240, 240, 240)'}
                      form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                      rawElement={{ ...line, parentId: multiLines.parentId } as ControlPayload}
                      removeElement={removeElement}
                      {...getRectangularFilterStyling(
                        line,
                        criticalFilter,
                        nonEffectiveFilter,
                        criticalFilterValues,
                        nonEffectiveFilterValues
                      )}
                      click={() => {
                        if (
                          userWithPermissions &&
                          userWithPermissions.permissions &&
                          userWithPermissions.permissions.editRecord &&
                          userWithPermissions.permissions.editRecord.controls
                        ) {
                          setElementToEdit(line.id);
                        }
                      }}
                    />{' '}
                  </div>
                );
              }
              return <div className="line-rectangular-container" style={{ width: '120px' }} key={i}></div>;
            })}
          </div>

          <div
            className="multi-element-second-line"
            style={{
              display: 'flex',
              flexDirection: alignment === CONTAINER_ALIGNMENT.LEFT ? 'row' : 'row-reverse',
            }}
          >
            {lines.map((line: BowtieBasicRecord, i: number) => {
              if (i % 2 !== 0) {
                if (line.value === null || elementToEdit === line.id) {
                  return (
                    <TextArea
                      key={`${line.id}_${i}`}
                      index={i}
                      id={id}
                      elementId={line.id}
                      addElement={addElement}
                      removeElement={removeElement}
                      lineDirection={LINE_DIRECTION.TOP}
                      parentId={multiLines.parentId}
                      defaultValue={line.value}
                      line={line}
                      setElementToEdit={setElementToEdit}
                      form={form}
                    />
                  );
                }
                if (i + 1 === lines.length) {
                  return (
                    <div className="line-rectangular-container" key={i}>
                      <Rectangle
                        color="#333"
                        link={line.linkUrl}
                        text={line.value}
                        borderWidth="2px"
                        status={line.status}
                        width="120px"
                        fontSize="12px"
                        id={`final_${id}_${index + 1}`}
                        hasLine={true}
                        lineColor={palettes.criticalControl.primary}
                        lineDirection={LINE_DIRECTION.TOP}
                        containerBackgroundColor={'rgb(240, 240, 240)'}
                        form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                        rawElement={{ ...line, parentId: multiLines.parentId } as ControlPayload}
                        removeElement={removeElement}
                        {...getRectangularFilterStyling(
                          line,
                          criticalFilter,
                          nonEffectiveFilter,
                          criticalFilterValues,
                          nonEffectiveFilterValues
                        )}
                        click={() => {
                          if (
                            userWithPermissions &&
                            userWithPermissions.permissions &&
                            userWithPermissions.permissions.editRecord &&
                            userWithPermissions.permissions.editRecord.controls
                          ) {
                            setElementToEdit(line.id);
                          }
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div className="line-rectangular-container" key={i} id={line.value}>
                    <Rectangle
                      color="#333"
                      link={line.linkUrl}
                      id={line.value}
                      text={line.value}
                      status={line.status}
                      borderWidth="2px"
                      width="120px"
                      fontSize="12px"
                      hasLine={true}
                      lineColor={palettes.criticalControl.primary}
                      lineDirection={LINE_DIRECTION.TOP}
                      containerBackgroundColor={'rgb(240, 240, 240)'}
                      form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                      rawElement={{ ...line, parentId: multiLines.parentId } as ControlPayload}
                      removeElement={removeElement}
                      {...getRectangularFilterStyling(
                        line,
                        criticalFilter,
                        nonEffectiveFilter,
                        criticalFilterValues,
                        nonEffectiveFilterValues
                      )}
                      click={() => {
                        if (
                          userWithPermissions &&
                          userWithPermissions.permissions &&
                          userWithPermissions.permissions.editRecord &&
                          userWithPermissions.permissions.editRecord.controls
                        ) {
                          setElementToEdit(line.id);
                        }
                      }}
                    />
                  </div>
                );
              }
              return <div className="line-rectangular-container" key={i} style={{ width: '120px' }}></div>;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiElementLine;

// Util
const criticalRectangularStyling = {
  backgroundColor: palettes.criticalControl.primary,
  borderColor: palettes.criticalControl.primary,
};

const nonEffectiveRectangularStyling = {
  backgroundColor: palettes.controlIssue.primary,
  borderColor: palettes.controlIssue.primary,
  color: palettes.text.white,
};

const criticalAndNonEffectiveRectangularStyling = {
  backgroundColor: palettes.lightBlue.primary,
  borderColor: palettes.lightBlue.primary,
};

const defaultRectangularStyling = {
  backgroundColor: palettes.lightBlue.primary,
  borderColor: palettes.lightBlue.primary,
};

function getRectangularFilterStyling(
  line: BowtieBasicRecord,
  criticalFilter: boolean,
  nonEffectiveFilter: boolean,
  criticalFilterValues: { truthy: string; falsy: string },
  nonEffectiveFilterValues: { truthy: string; falsy: string }
) {
  if (line.effectiveOrNotEffective && line.effectiveOrNotEffective.value === nonEffectiveFilterValues.falsy) {
    return nonEffectiveRectangularStyling;
  } else {
    if (
      criticalFilter &&
      nonEffectiveFilter &&
      line.criticalOrNonCritical &&
      (line.criticalOrNonCritical as string) === criticalFilterValues.truthy
    ) {
      return criticalRectangularStyling;
    } else if (
      criticalFilter &&
      line.criticalOrNonCritical &&
      (line.criticalOrNonCritical as string) === criticalFilterValues.falsy
    ) {
      return criticalAndNonEffectiveRectangularStyling;
    } else {
      return defaultRectangularStyling;
    }
  }
}
