import React, { useCallback, useEffect, useState, Ref, useImperativeHandle, forwardRef } from 'react';
import { useAppDispatch } from '../../../modules/hooks';
import diagramActions from '../../../modules/diagram/diagramActions';
import './zoom-controls.css';

interface ZoomControlsProps {
  zoomIn: () => void;
  zoomOut: () => void;
  zoomReset: () => void;
}

export interface ZoomControlsRef {
  getZoomPercentage: () => number;
}

const ZoomControls = ({ zoomIn, zoomOut, zoomReset }: ZoomControlsProps, ref: Ref<ZoomControlsRef>) => {
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const dispatch = useAppDispatch();

  useEffect(() => {
    resetZoomPercentage();
  }, []);

  useImperativeHandle(ref, () => ({ getZoomPercentage }));

  const getZoomPercentage = () => zoomPercentage;

  const resetZoomPercentage = useCallback(() => {
    setZoomPercentage(100);
  }, []);

  const resetScrollDiagramLayout = useCallback(() => {
    const mainAreaContainer = document.querySelector('.diagram-transform-container');

    mainAreaContainer?.scrollTo({
      left: 0,
      top: 0,
    });
  }, []);

  const handleZoomIn = () => {
    setZoomPercentage((oldZoomPercentage) => {
      if (oldZoomPercentage < 200) {
        return oldZoomPercentage + 20;
      }
      return oldZoomPercentage;
    });

    zoomIn();
  };

  const handleZoomOut = () => {
    setZoomPercentage((oldZoomPercentage) => {
      if (oldZoomPercentage > 20) {
        return oldZoomPercentage - 20;
      }
      return oldZoomPercentage;
    });

    zoomOut();
  };

  const handleZoomReset = () => {
    resetScrollDiagramLayout();

    setTimeout(() => {
      zoomReset();
      resetZoomPercentage();
      dispatch(
        diagramActions.doUpdateDiagramLayout({
          isWidth: false,
        })
      );
    }, 100);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '50px',
        top: '0px',
        right: '0px',
        zIndex: 4000,
        display: 'flex',
        justifyContent: 'row-reverse',
        padding: '0rem 2.7rem',
        marginTop: '-0.7rem',
      }}
      id="diagram-zoom-controls"
    >
      <div
        style={{
          marginLeft: 'auto',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 47px',
            color: 'white',
            width: '100%',
            height: '100%',
            gap: '5px',
          }}
        >
          <div className="zoom-percentage-container">
            <p>{zoomPercentage}%</p>
          </div>
          <div
            style={{
              backgroundColor: '#A4B0C7',
              padding: '3px',
            }}
            className="zoom-icon"
            onClick={handleZoomIn}
          >
            <ZoomPlusIcon />
          </div>
          <div
            style={{
              backgroundColor: '#A4B0C7',
              padding: '3px',
            }}
            className="zoom-icon"
            onClick={handleZoomOut}
          >
            <ZoomMinusIcon />
          </div>
          <div
            style={{
              backgroundColor: '#A4B0C7',
              padding: '3px',
            }}
            className="zoom-icon"
            onClick={handleZoomReset}
          >
            <ZoomResetIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ZoomControls);

// zoom controls loading
export const ZoomControlsLoading = React.memo(() => {
  return (
    <div className="diagram-zoom-controls" id="diagram-zoom-controls">
      <div
        style={{
          marginLeft: 'auto',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div className="zoom-container">
          <div className="zoom-percentage-container">
            <p>100%</p>
          </div>
          <div className="zoom-icon">
            <ZoomPlusIcon />
          </div>
          <div className="zoom-icon">
            <ZoomMinusIcon />
          </div>
          <div className="zoom-icon">
            <ZoomResetIcon />
          </div>
        </div>
      </div>
    </div>
  );
});
ZoomControlsLoading.displayName = 'ZoomControlsLoading';

// zoom control panel icons
const ZoomPlusIcon = React.memo(() => (
  <svg width="20" height="20" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6" r="5.75" fill="white" stroke="#A4B0C7" strokeWidth="0.5" />
    <line x1="9" y1="5.88806" x2="3" y2="5.88806" stroke="#A4B0C7" strokeWidth="0.5" />
    <line x1="5.78613" y1="3.15625" x2="5.78613" y2="9.15625" stroke="#A4B0C7" strokeWidth="0.5" />
  </svg>
));
ZoomPlusIcon.displayName = 'ZoomPlusIcon';

const ZoomMinusIcon = React.memo(() => (
  <svg width="20" height="20" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6" r="5.75" fill="white" stroke="#A4B0C7" strokeWidth="0.5" />
    <line x1="9" y1="5.88806" x2="3" y2="5.88806" stroke="#A4B0C7" strokeWidth="0.5" />
  </svg>
));
ZoomMinusIcon.displayName = 'ZoomMinusIcon';

const ZoomResetIcon = React.memo(() => (
  <svg width="20" height="20" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6" r="5.75" fill="white" stroke="#A4B0C7" strokeWidth="0.5" />
  </svg>
));
ZoomResetIcon.displayName = 'ZoomResetIcon';
