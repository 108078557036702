import React from 'react';

// Main area container
interface MainAreaContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

export const MainAreaContainer = ({ children }: MainAreaContainerProps) => {
  return (
    <div
      className="main-area-container"
      id="main-area-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        position: 'relative',
        padding: '0rem 8rem',
      }}
    >
      {children}
    </div>
  );
};

// Hazard container
interface HazardContainerProps {
  children: React.ReactNode;
}

export const HazardContainer = ({ children }: HazardContainerProps) => {
  return (
    <div
      className="hazard-container"
      style={{
        position: 'absolute',
        top: '0',
      }}
    >
      {children}
    </div>
  );
};

// Risk scenario container
interface RiskScenarioContainerProps {
  children: React.ReactNode;
}

export const RiskScenarioContainer = ({ children }: RiskScenarioContainerProps) => {
  return <div className="risk-scenario-container">{children}</div>;
};
