import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReloadButton = () => {
  const navigate = useNavigate();

  const handleClick = () => navigate(0);

  return (
    <div
      title="Reload"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#00398f', cursor: 'pointer' }}
    >
      <IconButton onClick={handleClick}>
        <OdinIcon size={OdinIconSize.Small} type={OdinIconType.Line} icon="Refresh" />
      </IconButton>
    </div>
  );
};

export default ReloadButton;
