import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import LinkIcon from '../../../../assets/icons/LinkIcon';
import UnlinkIcon from '../../../../assets/icons/UnlinkIcon';
import { ModalDialog } from '@myosh/odin-components';
import ActionModalContent from '../../../Modal/ActionModalContent';
import { useAppDispatch } from '../../../../modules/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { palettes } from '../../../../environment/environment';
import controlActions, { ControlPayload } from '../../../../modules/diagram/controlActions';
import './Rectangle.css';
import { DynamicFormSettings } from '../../../../services/form-data-types';
import { useAIContext } from '../../../../context/ai.context';

export const LINE_DIRECTION = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
};

interface RectangleProps {
  text: string;
  key?: string;
  fontSize: string;
  status?: string;
  containerBackgroundColor?: string;
  width: string;
  backgroundColor: string;
  color: string;
  borderWidth: string;
  borderColor: string;
  id: string;
  className?: string;
  hasCircleRight?: boolean;
  hasCircleLeft?: boolean;
  hasLine?: boolean;
  lineColor?: string;
  lineDirection?: string;
  editable?: boolean;
  parentId?: string;
  click: () => void;
  link?: string;
  form?: DynamicFormSettings;
  rawElement?: ControlPayload;
  // only used when editing AI generated diagrams
  removeElement?: (payload: { id: string; parentId: number }) => void;
}

// NOTE: This component's style handling needs to be improved
export default function Rectangle({
  text,
  width,
  backgroundColor,
  color,
  borderWidth,
  borderColor,
  fontSize,
  className,
  id,
  hasCircleRight,
  hasCircleLeft,
  hasLine,
  lineColor,
  lineDirection,
  link,
  status,
  containerBackgroundColor,
  click,
  form,
  rawElement,
  removeElement,
}: RectangleProps) {
  const [mouseEnter, setMouseEnter] = useState(false);
  const [open, setOpen] = useState(false);

  const externalLinkRef = useRef<HTMLDivElement>(null);
  const deleteLinkRef = useRef<HTMLDivElement>(null);

  const isAIGenerated = useAIContext();

  const dispatch = useAppDispatch();

  const formWorkflowSteps = form && form.workflowSteps;
  const border = backgroundColor === palettes.lightBlue.primary ? palettes.criticalControl.primary : borderColor;

  const draftWorkflowStep = formWorkflowSteps && formWorkflowSteps.find((workflowStep) => workflowStep.draft);
  const draftWorkflowStepLabel = draftWorkflowStep && draftWorkflowStep.label;

  const isNotControl = id.includes('cause') || id.includes('consequence');
  const isControlIssue = backgroundColor === palettes.controlIssue.primary;

  const [rectangularStyle, setRectangularStyle] = useState<CSSProperties>({
    backgroundColor: backgroundColor ?? '#ffffff',
    color: '#333',
    borderWidth: '1px',
    borderColor: borderColor ?? '#333',
    width: width ?? '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: fontSize ?? '12px',
    textAlign: 'center',
    borderStyle: 'solid',
    borderRadius: '0.6rem',
    zIndex: '3000',
    position: 'relative',
    height: id === 'hazard' || id === 'risk-scenario' ? '100px' : '50px',
  });

  useEffect(() => {
    if (link) {
      setRectangularStyle((oldRectangularStyle) => {
        return {
          ...oldRectangularStyle,
          width,
          backgroundColor,
          color,
          borderWidth,
          borderColor,
          fontSize,
        };
      });
    } else if (!link) {
      setRectangularStyle((oldRectangularStyle) => {
        return {
          ...oldRectangularStyle,
          borderStyle: 'solid',
          borderWidth: '2px',
          borderColor: borderColor ?? palettes.criticalControl.primary,
          backgroundColor: backgroundColor ?? 'rgb(240, 240, 240)',
          width: width ?? '120px',
        };
      });
    }
  }, [width, backgroundColor, color, borderWidth, borderColor, fontSize, link]);

  const onUnlinkClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (isAIGenerated === true && rawElement && removeElement) {
      // when it's an AI diagram, we can just remove the control without confirmation
      removeElement({ id: String(rawElement.id), parentId: rawElement.parentId });
    } else {
      setOpen(true);
    }
  };

  const onDelete = (rawElement?: ControlPayload) => {
    rawElement && dispatch(controlActions.unlinkControl(rawElement));
  };

  const onModalHidden = () => {
    setOpen(false);
  };

  return (
    <div
      id={id}
      style={{
        ...rectangularStyle,
        fontWeight: isNotControl || isControlIssue ? '300' : 'normal',
        color: !link ? (color ?? palettes.text.white) : isNotControl ? palettes.text.white : color,
      }}
      className={`rectangular-container ${className}`}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
      onClick={(event: React.MouseEvent) => {
        if (
          click &&
          (!externalLinkRef.current ||
            (externalLinkRef.current && !externalLinkRef.current.contains(event.currentTarget))) &&
          (!deleteLinkRef.current || (deleteLinkRef.current && !deleteLinkRef.current.contains(event.currentTarget)))
        ) {
          click();
        }
      }}
    >
      <ModalDialog header="" visible={open} hidden={onModalHidden} shouldCheckZIndex={true}>
        <ActionModalContent
          actionButton={{
            onAction: (event: React.MouseEvent) => {
              event.stopPropagation();
              event.nativeEvent.stopImmediatePropagation();

              setOpen(false);
              onDelete(rawElement);
            },
            text: 'Unlink',
          }}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();

            setOpen(false);
          }}
          text="Are you sure you want to remove this control from this Bowtie?"
        />
      </ModalDialog>
      {mouseEnter && link && (
        <div
          className="external-link-container"
          onClick={() => window.open(link)}
          ref={externalLinkRef}
          title="Open in Viking"
        >
          <LinkIcon height={20} width={20} color={border} />
        </div>
      )}
      {mouseEnter &&
        (isAIGenerated || (!id.includes('cause') && !id.includes('consequence'))) &&
        !id.includes('hazard') &&
        !id.includes('scenario') && (
          <div ref={deleteLinkRef} className="delete-link-container" onClick={onUnlinkClick} title="Unlink">
            <UnlinkIcon height={20} width={20} color={border} />
          </div>
        )}

      {rawElement?.global && (
        <div className="global-icon-container">
          <FontAwesomeIcon icon={faGlobe} color={palettes.criticalControl.primary} title="Global control" />
        </div>
      )}

      {!rawElement?.global &&
        ((status && status === 'Draft') || (draftWorkflowStepLabel && draftWorkflowStepLabel === status)) && (
          <div
            className="draft-marker"
            style={{
              backgroundColor: borderColor,
              borderColor: containerBackgroundColor,
            }}
            title="Draft"
          ></div>
        )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
        title={text}
      >
        <div
          className="button-radius"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            className="line-clamp"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              zIndex: 100,
            }}
          >
            <p
              style={{
                margin: 0,
                lineHeight: 1.2,
                whiteSpace: 'break-spaces',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineClamp: 3,
              }}
            >
              {text}
            </p>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            width: '115%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {hasCircleRight && <div className="circle circle-right"></div>}
          {hasCircleLeft && <div className="circle circle-left"></div>}
        </div>
      </div>

      {hasLine && (
        <div
          className="line"
          style={{
            background: lineColor,
            top: lineDirection === LINE_DIRECTION.BOTTOM ? '107%' : undefined,
            bottom: lineDirection === LINE_DIRECTION.TOP ? '105%' : undefined,
          }}
        ></div>
      )}
    </div>
  );
}
