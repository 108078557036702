import React, { useState, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../modules/hooks';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import Caret from '../../../assets/images/Caret.png';
import queryString from 'query-string';
import './DropDown.css';
import { DIAGRAM_FETCH_SCENARIO_RECORD_MORE } from '../../../modules/diagram/diagramTypes';
import diagramSelectors from '../../../modules/diagram/diagramSelectors';
import RecordServiceJS from '../../../modules/diagram/diagramService';
import { riskScenarioPageSize } from '../../../environment/environment';
import { useLocation } from 'react-router-dom';
import LoadingControls from '../../../components/Items/LoadingComponent/LoadingControls';

const recordService = new RecordServiceJS('test');
let page = 2;
let isScrolling = false;
let initialRecords = true;

const DropDown = () => {
  const bowtieConfiguration = JSON.parse(localStorage.getItem('bowtieConfiguration'));
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { records, loading, isLastPage } = useAppSelector(diagramSelectors.selectScenarioRecords);
  const scenario = useAppSelector(diagramSelectors.selectScenarioRecords);

  const [open, setOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [, setRecordState] = useState([]);

  const myStateRef = useRef(records);
  const dropDownRef = useRef();

  const queryParams = new URLSearchParams(location.search);

  const onToggle = useCallback(() => {
    setOpen(!open);
    setInitialLoad(false);
  }, [open]);

  useEffect(() => {
    document.addEventListener('click', (event) => {
      if (event.target.contains(dropDownRef.current)) {
        setOpen(false);
      }
    });

    return document.removeEventListener('click', onToggle);
  }, [onToggle, open]);

  const handleFetchMoreRecords = useCallback(async () => {
    if (initialRecords) {
      myStateRef.current = records;
    }

    const response = await recordService.fetchRecordsByFormId({
      formId: bowtieConfiguration?.forms?.main?.id,
      pageSize: riskScenarioPageSize,
      page,
      isNewRiskScenario: false,
    });
    const hits = response?.payload?.data?.result?.hits;
    page++;

    if (response.success && hits > 0) {
      const transformRecords = await recordService.doTransformScenarioRecords(
        response,
        bowtieConfiguration,
        bowtieConfiguration.forms.main.form
      );

      if (initialRecords) {
        const newRecords = [...records, ...transformRecords];
        myStateRef.current = newRecords;
        setRecordState(newRecords);

        dispatch({
          type: DIAGRAM_FETCH_SCENARIO_RECORD_MORE,
          payload: {
            records: newRecords,
            isLastPage: hits < riskScenarioPageSize,
          },
        });
      } else {
        setRecordState((prevState) => {
          const newRecords = [...prevState, ...transformRecords];

          dispatch({
            type: DIAGRAM_FETCH_SCENARIO_RECORD_MORE,
            payload: {
              records: newRecords,
              isLastPage: hits < riskScenarioPageSize,
            },
          });

          return newRecords;
        });
      }
      isScrolling = false;
      initialRecords = false;
    } else {
      setRecordState((prevState) => {
        const records = initialRecords ? (scenario?.records ?? []) : prevState;
        dispatch({
          type: DIAGRAM_FETCH_SCENARIO_RECORD_MORE,
          payload: {
            records,
            isLastPage: true,
          },
        });

        return prevState;
      });
    }
  }, [bowtieConfiguration, dispatch, records, scenario?.records]);

  const handleScrollEvent = useCallback(
    async (event) => {
      if (open && event.target.id === 'dropdown-scenario' && !isScrolling) {
        const isLastScroll = event.target.scrollHeight - Math.round(event.target.scrollTop);
        const elementHeight = event.target.clientHeight;

        if (isLastScroll <= elementHeight + 300) {
          isScrolling = true;
          await handleFetchMoreRecords();
        }
      }
    },
    [handleFetchMoreRecords, open]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent, true);

    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, [handleScrollEvent]);

  const openRecord = (id) => {
    const parsedSearch = queryString.parse(window.location.search);
    queryParams.delete('formId');
    queryParams.delete('moduleId');
    queryParams.set('records', id);
    const urlParsed = queryParams.toString();

    parsedSearch.records = id;
    window.open(window.location.origin + '?' + urlParsed.toString());
  };

  return (
    <div className="dropdown-container">
      <ul
        id="dropdown-scenario"
        className={`${!open && 'dropdown-list-active'} dropdown-list custom-scroll`}
        ref={dropDownRef}
      >
        {loading && initialLoad ? (
          <li className="dropdown-list-item dropdown-list-active-item border-none disabled" title="New Diagram">
            <div className="dropdown-list-item-circle new-diagram-indicator"></div>
            <div className="dropdown-list-item-label">New Diagram</div>
            <div className={`${!open && 'dropdown-list-item-inactive-image'} dropdown-list-item-image`}>
              <img src={Caret} alt="caret"></img>
            </div>
          </li>
        ) : records?.length > 0 ? (
          records?.map((dropdownItem) => {
            if (dropdownItem?.selected) {
              return (
                <li
                  className={`${!open && 'dropdown-list-inactive-item'} dropdown-list-item dropdown-list-active-item`}
                  onClick={onToggle}
                  title={dropdownItem.label}
                  key={dropdownItem.value}
                >
                  <div className="dropdown-list-item-circle" style={{ background: dropdownItem.itemColor }}></div>
                  <div className="dropdown-list-item-label">{dropdownItem.label}</div>
                  <div className={`${!open && 'dropdown-list-item-inactive-image'} dropdown-list-item-image`}>
                    <img src={Caret} alt="caret"></img>
                  </div>
                </li>
              );
            }

            if (open) {
              return (
                <li
                  className="dropdown-list-item dropdown-list-simple-item"
                  onClick={() => openRecord(dropdownItem.value)}
                  title={dropdownItem.label}
                  key={dropdownItem.value}
                >
                  <div className="dropdown-list-item-circle" style={{ background: dropdownItem.itemColor }}></div>
                  <div className="dropdown-list-item-label">{dropdownItem.label}</div>
                </li>
              );
            }
          })
        ) : (
          <li className="dropdown-list-item dropdown-list-active-item border-none disabled" title="New Diagram">
            <div className="dropdown-list-item-circle new-diagram-indicator"></div>
            <div className="dropdown-list-item-label">No Diagram</div>
            <div className={`${!open && 'dropdown-list-item-inactive-image'} dropdown-list-item-image`}>
              <img src={Caret} alt="caret"></img>
            </div>
          </li>
        )}
        {open && !isLastPage && (
          <div className="loading-more">
            <LoadingControls className="loading-size" />
          </div>
        )}
      </ul>
    </div>
  );
};

DropDown.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default DropDown;
