import React, { forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Button, DropDown, DropDownNoSelection, DropDownResult, JsonData, OdinIcon } from '@myosh/odin-components';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { palettes } from '../../../../environment/environment';
import { useLazyRisksQuery } from '../../../../modules/api/aiApi';
import Dialog, { DialogRef } from '../dialog';
import './bowtie-wizard.css';

interface BowtieWizardProps {
  onGenerateBowtie: (term: string, selectedRisk: string) => void;
}

export interface BowtieWizardRef {
  open: () => void;
  close: () => void;
}

interface RiskDropDownItem {
  text: string;
  value: string;
}

interface BowtieWizardState {
  canGenerateRisks: boolean;
  loading: boolean;
  risks: Array<RiskDropDownItem>;
  selectedRisk?: RiskDropDownItem;
}

const initialState: BowtieWizardState = {
  canGenerateRisks: false,
  loading: false,
  risks: [],
};

const BowtieWizard = ({ onGenerateBowtie }: BowtieWizardProps, ref: Ref<BowtieWizardRef>) => {
  const [state, setState] = useState<BowtieWizardState>(initialState);
  const { canGenerateRisks, loading, risks, selectedRisk } = state;

  const dialogRef = useRef<DialogRef>(null);
  const riskInputRef = useRef<HTMLInputElement>(null);

  const [getRisks] = useLazyRisksQuery();

  useImperativeHandle(ref, () => ({
    open: () => dialogRef.current?.open(),
    close,
  }));

  const close = useCallback(() => dialogRef.current?.close(), []);

  const handleDialogClose = useCallback(() => {
    setState(initialState);

    if (riskInputRef.current) {
      riskInputRef.current.value = '';
    }
  }, []);

  const handleDialogCancel = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
  }, []);

  const handleRiskInputChange = () => {
    if (!riskInputRef.current?.value) {
      setState((state) => ({ ...state, canGenerateRisks: false, selectedRisk: undefined }));
    }
  };

  const handleGenerateRisk = () => {
    if (riskInputRef.current?.value && riskInputRef.current.value.trim().length > 0) {
      setState((state) => ({ ...state, canGenerateRisks: true, loading: true, selectedRisk: undefined }));

      getRisks({ term: riskInputRef.current.value.trim() })
        .unwrap()
        .then((data) => {
          const risks = data.map((risk) => {
            return { text: risk, value: risk };
          });

          setState((state) => ({ ...state, risks }));
        })
        .catch(() => {
          toast.error('Unable to fetch risks, please try again later.');
        })
        .finally(() => {
          setState((state) => ({ ...state, loading: false }));
        });
    }
  };

  const handleRiskSelectionChange = (risk?: DropDownResult | DropDownResult[] | DropDownNoSelection) => {
    if (risk && !Array.isArray(risk)) {
      setState((state) => ({ ...state, selectedRisk: risk as RiskDropDownItem }));
    }
  };

  const handleGenerateBowtieClick = () => {
    if (riskInputRef.current?.value && riskInputRef.current.value.trim().length > 0 && selectedRisk) {
      onGenerateBowtie(riskInputRef.current.value.trim(), selectedRisk.value);
      close();
    }
  };

  const sectionTwoStyles = classNames('bowtie-wizard-section-two', { disabled: !canGenerateRisks || loading });

  const dropDownSelectorStyles = classNames('dropdown-selector-styles', {
    'show-placeholder': canGenerateRisks && !loading && !selectedRisk,
  });

  return (
    <Dialog ref={dialogRef} onCancel={handleDialogCancel} onClose={handleDialogClose} className="bowtie-wizard">
      <div className="bowtie-wizard-title">Bowtie AI Wizard</div>
      <div title="Close" className="bowtie-wizard-close" onClick={close} style={{ color: palettes.darkBlue.primary }}>
        <OdinIcon icon="Close" />
      </div>
      <div className="bowtie-wizard-sections">
        <div className="bowtie-wizard-section-one">
          <div className="risk-input-field">
            <label htmlFor="risk-input">Describe an area or term to generate Risks for</label>
            <input ref={riskInputRef} name="risk-input" onChange={handleRiskInputChange} />
          </div>
          <Button onClick={handleGenerateRisk} disabled={canGenerateRisks && loading}>
            {canGenerateRisks && loading ? 'Generating...' : 'Generate Risks'}
          </Button>
        </div>
        <div className={sectionTwoStyles}>
          <DropDown
            label="Risk"
            data={risks as unknown as JsonData}
            textField="text"
            valueField="value"
            allowClear={false}
            allowSearch={true}
            onChange={handleRiskSelectionChange}
            value={selectedRisk}
            selectorStyles={dropDownSelectorStyles}
          />
          <Button type="primary" onClick={handleGenerateBowtieClick} disabled={!selectedRisk}>
            Generate Bowtie
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default forwardRef(BowtieWizard);
