import { createApi } from '@reduxjs/toolkit/query/react';
import { v4 as uuid4 } from 'uuid';
import { FactorsRequestBody, FactorsResponse, RisksRequestBody, RisksResponse } from '../../@types/ai-api.types';
import { BowtieCorrectionRequest, BowtieCorrectionResponse } from '../../@types/suggest.ai-api.types';
import { baseQueryWithReAuth } from './api';

const AI_API_SUFFIX = 'services/ai/v1';

export const aiApi = createApi({
  reducerPath: 'aiApi',
  baseQuery: (args, api, extraOptions) => baseQueryWithReAuth(args, api, { ...extraOptions, apiSuffix: AI_API_SUFFIX }),
  endpoints: (builder) => ({
    risks: builder.query<RisksResponse, RisksRequestBody>({
      query: (args) => ({
        url: '/risks',
        method: 'POST',
        body: args,
      }),
    }),
    factors: builder.query<FactorsResponse, FactorsRequestBody>({
      query: (args) => ({
        url: '/factors',
        method: 'POST',
        body: args,
      }),
      transformResponse: (response: FactorsResponse) => {
        // align the shape of the data with the existing bowtie data

        const causes = response.causes.map((cause) => {
          const id = uuid4();

          const causes = cause.controls.map((control: Record<string, unknown>) => {
            const controlId = uuid4();
            return {
              ...control,
              id: controlId,
              value: control.name,
              parentId: id,
            };
          });

          return {
            ...cause,
            id: id,
            uuid: id,
            value: cause.name,
            controls: [...causes],
            preventativeControls: [...causes],
          };
        });

        const consequences = response.consequences.map((consequence) => {
          const id = uuid4();

          const consequences = consequence.controls.map((control: Record<string, unknown>) => {
            const controlId = uuid4();
            return {
              ...control,
              id: controlId,
              value: control.name,
              parentId: id,
            };
          });

          return {
            ...consequence,
            id: id,
            uuid: id,
            value: consequence.name,
            controls: [...consequences],
            mitigatingControls: [...consequences],
          };
        });

        return { causes, consequences };
      },
    }),
    suggest: builder.query<
      BowtieCorrectionResponse,
      BowtieCorrectionRequest
    >({
      query: (args) => ({
        url: '/suggest',
        method: 'POST',
        body: args,
      }),
    }),
  }),
});

export const { useLazyRisksQuery, useLazyFactorsQuery, useLazySuggestQuery } = aiApi;
