import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { BowtieStateCauseData, BowtieStateConsequenceData } from '../../../../services/bowtie-data-types';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import { palettes } from '../../../../environment/environment';
import diagramSelectors from '../../../../modules/diagram/diagramSelectors';
import userSelectors from '../../../../modules/user/userSelectors';
import diagramActions from '../../../../modules/diagram/diagramActions';
import LoadingControls from '../../../Items/LoadingComponent/LoadingControls';
import Rectangle from '../rectangle/rectangle';
import { defaultStyle, savingStyle } from '../../../../helpers/config';
import RecordServiceJS from '../../../../modules/diagram/diagramService';
import { useAIContext } from '../../../../context/ai.context';
import { ControlPayload } from '../../../../modules/diagram/controlActions';

const recordService = new RecordServiceJS('test');

// SingleElementLine component
interface SingleElementLineProps {
  line: BowtieStateCauseData | BowtieStateConsequenceData;
  id: string;
  index: number;
  backgroundColor: string;
}

const SingleElementLine = ({ line, index, id }: SingleElementLineProps) => {
  const [disabled, setDisabled] = useState(false);
  const [editElement, setEditElement] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const isAIGenerated = useAIContext();

  const dispatch = useAppDispatch();
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const [rectangularStyle, setRectangularStyle] = useState<CSSProperties>({
    color: palettes.text.primary,
    borderWidth: '1px',
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'center',
    borderStyle: 'dashed',
    borderRadius: '0.6rem',
    zIndex: '3000',
    position: 'relative',
    outline: 'none',
    height: '50px',
    padding: '10px',
    borderColor: palettes.criticalControl.primary,
    backgroundColor: palettes.lightWhite.primary,
  });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const addElement = (payload: { id: number | string; value: string }) => {
    if (isAIGenerated === true) {
      if (id === 'causes') {
        dispatch(diagramActions.addCauseToAIDiagram(payload));
      } else {
        dispatch(diagramActions.addConsequenceToAIDiagram(payload));
      }
    } else {
      if (id === 'causes') {
        dispatch(diagramActions.doAddCause(payload));
      } else {
        dispatch(diagramActions.doAddConsequence(payload));
      }
    }
  };

  const removeElement = (payload: { id: number | string; parentId?: number }) => {
    if (id === 'causes') {
      dispatch(diagramActions.doRemoveCause(payload.id));
    } else {
      dispatch(diagramActions.doRemoveConsequence(payload.id));
    }
  };

  const onAddElement = async () => {
    if (inputRef.current?.value) {
      setDisabled(true);
      setRectangularStyle({
        ...rectangularStyle,
        ...savingStyle,
      });

      if (inputRef.current.value === line.value) {
        setEditElement(false);
        setDisabled(false);
        setRectangularStyle({
          ...rectangularStyle,
          ...defaultStyle,
        });
      } else if (editElement) {
        if (isAIGenerated === true) {
          if (id === 'causes') {
            dispatch(diagramActions.updateCauseInAIDiagram({ id: line.id!, value: inputRef.current.value }));
          } else {
            dispatch(diagramActions.updateConsequenceInAIDiagram({ id: line.id!, value: inputRef.current.value }));
          }
        } else {
          if (id === 'causes') {
            await recordService.updateCause(bowtieData, line, inputRef.current.value);
          } else {
            await recordService.updateConsequence(bowtieData, line, inputRef.current.value);
          }
        }

        setEditElement(false);
        setDisabled(false);
        setRectangularStyle({
          ...rectangularStyle,
          ...defaultStyle,
        });
      } else {
        setEditElement(false);
        addElement({
          id: line.id!,
          value: inputRef.current.value,
        });
        setRectangularStyle({
          ...rectangularStyle,
          ...savingStyle,
        });
        setDisabled(true);
      }
    } else if (inputRef.current?.value.length === 0 && isAIGenerated && editElement) {
      setDisabled(false);
      setEditElement(false);
      removeElement({ id: line.id! });
    } else {
      setDisabled(false);
      setEditElement(false);
    }
  };

  const handleOnKeyDown = async (event: React.KeyboardEvent) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      inputRef.current?.blur();
    }
  };

  const handleOnRectangularEdit = () => {
    if (
      userWithPermissions &&
      userWithPermissions?.permissions &&
      userWithPermissions?.permissions?.editRecord &&
      userWithPermissions?.permissions?.editRecord?.[id]
    ) {
      setEditElement(true);
      setDisabled(false);
      setRectangularStyle({
        ...rectangularStyle,
        ...defaultStyle,
      });
    }
  };

  if (!line.value || editElement) {
    return (
      <div>
        <textarea
          onKeyDown={handleOnKeyDown}
          key={index}
          defaultValue={line.value ? line.value : ''}
          id={`${id + '_' + (index + 1)}`}
          ref={inputRef}
          disabled={disabled}
          onBlur={onAddElement}
          style={{
            ...rectangularStyle,
            marginBottom: '8rem',
            resize: 'none',
          }}
          rows={2}
        />
        {disabled && (
          <div className="box-loading-causes">
            <LoadingControls />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="single-element-line-container" key={index}>
      <Rectangle
        link={line.linkUrl}
        id={`${id + '_' + (index + 1)}`}
        text={line.value}
        color="#ffffff"
        backgroundColor={palettes.lightGray.primary}
        borderColor={palettes.lightGray.primary}
        form={
          id === 'causes'
            ? bowtieData?.bowtieConfiguration?.forms?.causes?.form
            : bowtieData?.bowtieConfiguration?.forms?.consequences?.form
        }
        borderWidth="2px"
        width="120px"
        fontSize="12px"
        status={line.status}
        containerBackgroundColor={'rgb(255, 246, 233)'}
        click={handleOnRectangularEdit}
        // 'rawElement' and 'removeElement are relevant only for editing AI generated diagrams
        rawElement={{ ...line } as unknown as ControlPayload}
        removeElement={removeElement}
      />
    </div>
  );
};

export default SingleElementLine;
