import {
  Button,
  DomElementAlignment,
  DomTargetPosition,
  OdinIcon,
  OdinIconType,
  OverlayPanel,
} from '@myosh/odin-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAIContext } from '../../../context/ai.context';
import filterActions from '../../../modules/filter/filterActions';
import filterSelectors from '../../../modules/filter/filterSelectors';
import { useAppDispatch, useAppSelector } from '../../../modules/hooks';
import userSelectors from '../../../modules/user/userSelectors';
import { DIAGRAM_MODE } from '../../../services/bowtie-data-types';
import AIAnalysisSuggestion from '../../../views/main/components/ai-analysis-suggestion/ai-analysis-suggestion';
import DropDown from '../../../views/shared/components/DropDown';
import AboutModal from './about-modal';
import './Header.css';
import ReloadButton from './reload-button';
import BowtieWizard, { BowtieWizardRef } from './wizard/bowtie-wizard';
import SaveButton from './wizard/save-button.component';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const menuButtonRef = useRef(null);
  const wizardRef = useRef<BowtieWizardRef>(null);

  const dispatch = useAppDispatch();
  const criticalFilter = useAppSelector(filterSelectors.selectCriticalFilter);
  const nonEffectiveFilter = useAppSelector(filterSelectors.selectNonEffectiveFilter);
  const diagramMode = useAppSelector(filterSelectors.selectDiagramMode);
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isAIGenerated = useAIContext();

  const defaultItems = [
    {
      name: 'Bowtie',
      action: () => toggleDiagramMode(DIAGRAM_MODE.BOWTIE),
      icon: 'Information',
      className: diagramMode === DIAGRAM_MODE.BOWTIE ? 'setting-selected-color' : '',
    },
    {
      name: 'Butterfly',
      action: () => toggleDiagramMode(DIAGRAM_MODE.BUTTERFLY),
      icon: 'MenuFold',
      className: diagramMode === DIAGRAM_MODE.BUTTERFLY ? 'setting-selected-color' : '',
    },
    {
      name: 'About',
      action: () => {
        setIsAboutModalOpen(true);
        setIsMenuOpen(false);
      },
    },
  ];

  const toggleDiagramMode = (selectedDiagramMode: string) => {
    setIsMenuOpen(false);
    dispatch(filterActions.toggleDiagramMode(selectedDiagramMode));
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAddBowtie = useCallback(() => {
    const configuration = JSON.parse(localStorage.getItem('bowtieConfiguration') ?? '');
    const { id } = configuration?.forms?.main;

    if (queryParams.has('records')) {
      queryParams.delete('records');
    }

    queryParams.set('formId', id);

    window.open(window.location.origin + '?' + queryParams.toString());
  }, []);

  const handleBowtieWizardClick = useCallback(() => {
    return wizardRef.current?.open();
  }, []);

  const focusRiskScenarioContainer = useCallback(() => {
    const riskScenarioContainer = document.getElementById('risk-scenario');
    if (riskScenarioContainer) {
      riskScenarioContainer.focus();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      focusRiskScenarioContainer();
    });
  }, []);

  const handleGenerateBowtie = (term: string, selectedRisk: string) => {
    if (term && selectedRisk) {
      const storedSchema = localStorage.getItem('schema') ?? '';
      // open new page and load bowtie
      const params = new URLSearchParams({ schemaName: storedSchema, term: term, risk: selectedRisk });
      window.open(`${window.location.origin}?${params}`);
    } else {
      toast.error('Please make sure that you have specified a term and have selected a risk.');
    }
  };

  const canCreateBowtie =
    userWithPermissions &&
    userWithPermissions.permissions &&
    userWithPermissions.permissions.createRecord &&
    userWithPermissions.permissions.createRecord.main;

  return (
    <>
      <div className="header-container" id="header">
        <div className="first-row-container">
          <div className="first-row">
            {!isAIGenerated && <DropDown />}
            {!isAIGenerated && canCreateBowtie && (
              <>
                <Button onClick={handleAddBowtie} type="primary">
                  Add Bowtie
                </Button>
                <Button onClick={handleBowtieWizardClick}>Bowtie AI Wizard</Button>
                <AIAnalysisSuggestion />
              </>
            )}
            {!isAIGenerated && <ReloadButton />}
            {isAIGenerated && <SaveButton />}
          </div>
          <div className="diagram-toggle-container">
            <div className="filter-button-container">
              <div className="legend">
                <div className="legend-container">
                  <div id="critical" className={`legend-box filter-button control`} />
                  <label>Control</label>
                </div>
                <div className="legend-container">
                  <div
                    id="critical"
                    className={`legend-box filter-button ${criticalFilter ? 'critical-active' : ''}`}
                  />
                  <label>Critical Control</label>
                </div>
                <div className="legend-container">
                  <div
                    id="non-critical"
                    className={`legend-box filter-button ${nonEffectiveFilter ? 'non-critical-active' : ''}`}
                  />
                  <label>Control Issues</label>
                </div>
              </div>
            </div>
            <div title="Switch layout" className="setting">
              <div ref={menuButtonRef} onClick={toggleMenu} className="setting-icon">
                <OdinIcon icon="Menu" type={OdinIconType.Line} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverlayPanel
        visible={isMenuOpen}
        target={menuButtonRef.current}
        targetPosition={DomTargetPosition.BottomRight}
        elementAlignment={DomElementAlignment.TopRight}
        hidden={() => setIsMenuOpen(false)}
        shouldCheckZIndex
      >
        <div className="list-container">
          {defaultItems.map((item) => {
            return (
              <div key={item.name} className={'list-item ' + item.className} onClick={item.action}>
                {item.name}
              </div>
            );
          })}
        </div>
      </OverlayPanel>
      <AboutModal isOpen={isAboutModalOpen} onClose={() => setIsAboutModalOpen(false)} />
      <BowtieWizard ref={wizardRef} onGenerateBowtie={handleGenerateBowtie} />
    </>
  );
}
