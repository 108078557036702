import { BowtieConfiguration } from './common-data-types';
import { RecordResult } from './record-data-types';

export enum DIAGRAM_MODE {
  BOWTIE = 'BOWTIE',
  BUTTERFLY = 'BUTTERFLY',
}
export interface BowtieStateData {
  scenarioRecord: RecordResult;
  bowtieConfiguration: BowtieConfiguration;
  scenario: string;
  hazard: string;
  causes?: Array<BowtieStateCauseData>;
  consequences?: Array<BowtieStateConsequenceData>;
  mitigatingControls?: Array<BowtieConsequenceRecord>;
  preventativeControls?: Array<BowtieCauseRecord>;
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export type BowtiePreviewStateData = Optional<BowtieStateData, 'scenarioRecord' | 'bowtieConfiguration' | 'hazard'>;

export interface BowtieStateControlData {
  form: {
    id: number;
    moduleId: number;
  };
  id: number | string;
  uuid: number;
  value: string;
  status: string;
  linkUrl?: string;
}

export interface BowtieStateCauseData extends BowtieStateControlData {
  preventativeControls: Array<BowtieCauseRecord>;
}

export interface BowtieStateConsequenceData extends BowtieStateControlData {
  mitigatingControls: Array<BowtieConsequenceRecord>;
}

export interface BowtieMappedData {
  scenarioRecord: RecordResult;
  bowtieConfiguration?: BowtieConfiguration;
  scenario?: unknown;
  hazard?: unknown | null;
  causes?: Array<BowtieBasicRecord>;
  consequences?: Array<BowtieBasicRecord>;
  mitigatingControls?: Array<BowtieConsequenceRecord>;
  preventativeControls?: Array<BowtieCauseRecord>;
}

export interface BowtieBasicRecord {
  id?: number;
  value: string;
  uuid?: number;
  status: string;
  linkUrl?: string;
  effectiveOrNotEffective?: { value: string };
  criticalOrNonCritical?: unknown;
  global?: boolean;
}

export interface BowtieConsequenceRecord extends BowtieBasicRecord {
  consequences: Array<BowtieBasicRecord>;
}

export interface BowtieCauseRecord extends BowtieBasicRecord {
  causes: Array<BowtieBasicRecord>;
}

export interface ControlCauseLink {
  controlId: number | null;
  causeIds: Array<string>;
}
