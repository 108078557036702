import React from 'react';
import Controls from '../../../../views/shared/components/controls.component';
import LoadingPreview from '../loading-preview';

interface PreventativeControlsProps {
  isLoading: boolean;
  color: string;
}

const PreventativeControls = ({ isLoading, color }: PreventativeControlsProps) => {
  return (
    <Controls
      title={'Preventative Controls'}
      id="preventative-controls-container"
      content={
        isLoading ? (
          <LoadingPreview message="Preventative controls will be displayed here" />
        ) : (
          'Preventative controls will be displayed here'
        )
      }
      height={'75vh'}
      width={'20vw'}
      color={color}
    />
  );
};

export default PreventativeControls;
