import React from 'react';
import Controls from '../../../../views/shared/components/controls.component';
import LoadingPreview from '../loading-preview';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import userSelectors from '../../../../modules/user/userSelectors';
import { palettes } from '../../../../environment/environment';
import diagramActions from '../../../../modules/diagram/diagramActions';

interface ConsequencesProps {
  isLoading: boolean;
}

const Consequences = ({ isLoading }: ConsequencesProps) => {
  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const canCreateConsequences =
    userWithPermissions &&
    userWithPermissions.permissions &&
    userWithPermissions.permissions.createRecord &&
    userWithPermissions.permissions.createRecord.consequences;

  const addConsequenceInput = () => {
    dispatch(diagramActions.doAddConsequenceInput());
  };

  return (
    <Controls
      title={'Consequences'}
      id="consequences-container"
      content={
        isLoading ? (
          <LoadingPreview message="Consequences will be displayed here" />
        ) : canCreateConsequences ? (
          'Add consequences in this box'
        ) : (
          'Consequences will be displayed here'
        )
      }
      add={isLoading ? null : canCreateConsequences}
      height={'75vh'}
      width={'10vw'}
      color={palettes.criticalControl.primary}
      onAdd={addConsequenceInput}
    />
  );
};

export default Consequences;
