import { compact, uniq } from 'lodash';
import queryString from 'query-string';
import { riskScenarioPageSize } from '../../environment/environment';
import { keys } from '../../helpers/config';
import Request from '../../helpers/request';
import { buildUrl } from '../../helpers/urlManager';

const unique = (array) => {
  return array.filter((item, index, array) => {
    return array.indexOf(item) === index;
  });
};

export default class RecordServiceJS {
  env = '';
  envConfig = {};

  constructor(env) {
    this.env = env;
    this.envConfig = keys[env];
  }

  addRiskScenario(form, labelField, labelValue, draftStatus) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple` }, {});

    let status = 'Draft';
    if (draftStatus) {
      status = draftStatus;
    } else if (form.workflowSteps && form.workflowSteps.length > 0) {
      status = form.workflowSteps.find((step) => step.draft === true)?.label ?? 'Default';
    }

    return Request.doRequest({
      url: requestUrl,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: [
        {
          formId: form.id,
          hierarchies: {},
          fields: {
            [labelField]: labelValue,
          },
          status: status,
        },
      ],
    });
  }

  updateRiskScenarioHazard(riskScenarioRecord, hazardFieldLabel, hazardFieldValue) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${riskScenarioRecord.id}` }, {});

    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        formId: riskScenarioRecord.formId,
        formName: riskScenarioRecord.formName,
        status: riskScenarioRecord.status,
        id: riskScenarioRecord.id,
        fields: {
          [hazardFieldLabel]: hazardFieldValue,
        },
      },
    });
  }

  updateRiskScenarioRecord(riskScenarioId, riskScenarioPayload) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${riskScenarioId}` }, {});

    const editableField = Object.keys(riskScenarioPayload.fields).find((field) => {
      if (riskScenarioPayload.mitigating) {
        return field === 'Mitigating Controls';
      }

      if (riskScenarioPayload.preventative) {
        return field === 'Preventative Controls';
      }

      return field === 'Preventative Controls';
    });

    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        formId: riskScenarioPayload.formId,
        formName: riskScenarioPayload.formName,
        status: riskScenarioPayload.status,
        id: riskScenarioId,
        fields: {
          [riskScenarioPayload.mitigating ? 'Mitigating Controls' : 'Preventative Controls']: editableField
            ? uniq([...riskScenarioPayload.fields[editableField], String(riskScenarioPayload.linkedRecordId)])
            : [String(riskScenarioPayload.linkedRecordId)],
        },
      },
    });
  }

  updateRiskScenarioField(bowtieData, value) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `records/simple/${bowtieData.scenarioRecord.id}`,
      },
      {}
    );

    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        id: bowtieData.scenarioRecord.id,
        formId: bowtieData.scenarioRecord.formId,
        formName: bowtieData.scenarioRecord.formName,
        status: bowtieData.scenarioRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.scenario.captionField]: value,
        },
      },
    });
  }

  updateCause(bowtieData, causeRecord, value) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${causeRecord.uuid}` }, {});
    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        id: causeRecord.uuid,
        formId: bowtieData.bowtieConfiguration.forms.causes.id,
        status: causeRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.preventativeControls.causes.captionField]: value,
        },
      },
    });
  }

  updateConsequence(bowtieData, consequenceRecord, value) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${consequenceRecord.uuid}` }, {});
    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        id: consequenceRecord.uuid,
        formId: bowtieData.bowtieConfiguration.forms.consequences.id,
        status: consequenceRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.mitigatingControls.consequences.captionField]: value,
        },
      },
    });
  }

  updatePreventativeControl(bowtieData, preventativeControlRecord, value) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `records/simple/${preventativeControlRecord.id}`,
      },
      {}
    );
    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        id: preventativeControlRecord.id,
        formId: bowtieData.bowtieConfiguration.forms.controls.id,
        status: preventativeControlRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.preventativeControls.captionField]: value,
        },
      },
    });
  }

  updateMitigatingControl(bowtieData, mitigatingControlRecord, value) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `records/simple/${mitigatingControlRecord.id}`,
      },
      {}
    );
    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        id: mitigatingControlRecord.id,
        formId: bowtieData.bowtieConfiguration.forms.controls.id,
        status: mitigatingControlRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.mitigatingControls.captionField]: value,
        },
      },
    });
  }

  fetchRecords(formId, recordIds) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `forms/${formId}/records` },
      {
        filter: `id:in:${compact(recordIds).join(',')}`,
      }
    );

    return Request.doRequest({
      url: requestUrl,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
    });
  }

  fetchRecordsByFormId(params) {
    const { formId, pageSize, page, isNewRiskScenario } = params;
    const authorizationHeader = localStorage.getItem('idToken');
    const parsedSearch = queryString.parse(window.location.search);
    const isEqual = isNewRiskScenario ? 'eq' : 'neq';
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `forms/${formId}/records`,
      },
      {
        pageSize,
        page,
        filter: !parsedSearch?.records ? '' : `id:${isEqual}:${parsedSearch?.records}`,
        sort: 'Risk Scenario',
        order_by: 'ASC',
      }
    );

    return Request.doRequest({
      url: requestUrl,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
    });
  }

  async doTransformScenarioRecords(bowtieRecordsResponse, bowtieConfiguration, mainFormPayload) {
    const { scenario: riskScenario } = bowtieConfiguration;
    const {
      payload: {
        data: {
          result: { results },
        },
      },
    } = bowtieRecordsResponse;
    const parsedSearch = queryString.parse(window.location.search);

    return results
      .filter((result) => result.fields)
      .map((result) => {
        return {
          value: result.id,
          label: result.fields.find((field) => field.name === riskScenario.captionField)?.value,
          selected: result.id === Number(parsedSearch.records),
          itemColor:
            mainFormPayload.workflowSteps && mainFormPayload.workflowSteps.length > 0
              ? mainFormPayload.workflowSteps.find((workflowStep) => {
                  return (
                    workflowStep.label &&
                    workflowStep.label.translations &&
                    workflowStep.label.translations[0].value === result.status
                  );
                })?.backgroundColor
              : null,
        };
      })
      .filter((result) => result.label);
  }

  async doFetchScenarioRecords(params) {
    const { formId, isNewRiskScenario } = params;

    return await this.fetchRecordsByFormId({
      formId: formId,
      pageSize: riskScenarioPageSize,
      page: 1,
      isNewRiskScenario,
    });
  }

  async doFilterScenarioRecord(record, bowtieConfiguration, mainFormPayload, isNewRiskScenario = false) {
    const bowtieRecordsPayload = await this.doFetchScenarioRecords({
      formId: record?.formId,
      isNewRiskScenario,
    });

    const { scenario: riskScenario } = bowtieConfiguration;

    if (bowtieRecordsPayload.success) {
      const form = mainFormPayload;

      let records = await this.doTransformScenarioRecords(bowtieRecordsPayload, bowtieConfiguration, form);
      const selectedRecord = records.find((record) => record.selected);
      records = records.filter((record) => !record.selected);

      if (!!selectedRecord) {
        records.unshift(selectedRecord);
      } else {
        records.unshift({
          value: record.id,
          label: record?.fields.find((field) => field.name === riskScenario.captionField)?.value,
          selected: true,
          itemColor: '#0ba600',
        });
      }

      return records;
    }
  }

  async doGetBowtieConfiguration(configuration) {
    let bowtieConfiguration = {};

    try {
      bowtieConfiguration = JSON.parse(configuration);
    } catch (err) {
      throw new Error(err);
    }

    return bowtieConfiguration;
  }

  // Fetching the mitigating controls with consequences

  async fetchMitigatingControlsWithConsequences(
    bowtieConfiguration,
    mitiGatingControlRecordLinkField,
    mitigatingControls
  ) {
    let toBeFetchedConsequences = [];
    if (
      mitiGatingControlRecordLinkField &&
      mitiGatingControlRecordLinkField.value &&
      mitiGatingControlRecordLinkField.value.length > 0
    ) {
      const recordsPayload = await this.fetchRecords(
        bowtieConfiguration.forms?.controls?.id,
        mitiGatingControlRecordLinkField.value
      );
      if (
        recordsPayload.success &&
        recordsPayload.payload &&
        recordsPayload.payload.data &&
        recordsPayload.payload.data.result &&
        recordsPayload.payload.data.result.results &&
        recordsPayload.payload.data.result.results.length > 0
      ) {
        const records = recordsPayload.payload.data.result.results;
        const consequencesFields = records
          .filter((record) => record.fields)
          .map((record) => {
            const consequencesRecordLinkField = record.fields.find(
              (field) => field.name === mitigatingControls.consequences.recordLinkField
            );
            if (
              consequencesRecordLinkField &&
              consequencesRecordLinkField.value &&
              consequencesRecordLinkField.value.length > 0
            ) {
              toBeFetchedConsequences = toBeFetchedConsequences.concat(consequencesRecordLinkField.value);
              return {
                recordId: record.id,
                consequenceId: consequencesRecordLinkField.value,
              };
            } else {
              return { recordId: null, consequenceId: null };
            }
          });
        const consequencesRecordPayload = await this.fetchRecords(
          bowtieConfiguration.forms?.consequences?.id,
          consequencesFields.map((field) => field.consequenceId)?.flat()
        );
        if (
          consequencesRecordPayload &&
          consequencesRecordPayload.success &&
          consequencesRecordPayload.payload &&
          consequencesRecordPayload.payload.data &&
          consequencesRecordPayload.payload.data.result &&
          consequencesRecordPayload.payload.data.result.results &&
          consequencesRecordPayload.payload.data.result.results.length > 0
        ) {
          const consequences = consequencesRecordPayload.payload.data.result.results;
          const mappedConsequences = consequences
            .filter((consequence) => consequence.fields)
            .map((consequence) => {
              const consequencesCaptionField = consequence.fields.find(
                (field) => field.name === mitigatingControls.consequences.captionField
              );

              return {
                id: consequence && consequence.id && consequence.id,
                uuid: consequence && consequence.id && consequence.id,
                value: consequencesCaptionField && consequencesCaptionField.value && consequencesCaptionField.value,
                status: consequence.status,
                linkUrl:
                  consequence && consequence.linkUrl && consequence.linkUrl.length > 0 ? consequence.linkUrl : null,
              };
            });
          const mappedRecords = records
            .filter((record) => record.fields)
            .map((record) => {
              let effectiveOrNotEffectiveField = null;
              const captionField = record.fields.find((field) => field.name === mitigatingControls.captionField);

              const criticalOrNonCriticalField = record.fields.find(
                (field) => field.name === mitigatingControls.criticalOrNonCriticalField
              );

              if (
                mitigatingControls.effectiveOrNotEffectiveField &&
                record[mitigatingControls.effectiveOrNotEffectiveField]
              ) {
                effectiveOrNotEffectiveField = {
                  value: {
                    value: record[mitigatingControls.effectiveOrNotEffectiveField],
                  },
                };
              } else {
                effectiveOrNotEffectiveField = record.fields.find(
                  (field) => field.name === mitigatingControls.effectiveOrNotEffectiveField
                );
              }

              const recordConsequencesFields = consequencesFields.find(
                (field) => field.recordId === record.id
              )?.consequenceId;

              const globalField = record.fields.find((field) => field.name === 'Global');

              return {
                id: record.id,
                value: captionField.value,
                uuid: record.id,
                status: record.status,
                linkUrl: record.linkUrl && record.linkUrl.length > 0 ? record.linkUrl : null,
                consequences: recordConsequencesFields
                  ? mappedConsequences.filter((consequence) =>
                      recordConsequencesFields.includes(String(consequence.id))
                    )
                  : [],
                effectiveOrNotEffective:
                  effectiveOrNotEffectiveField &&
                  effectiveOrNotEffectiveField.value &&
                  effectiveOrNotEffectiveField.value.value,
                criticalOrNonCritical:
                  criticalOrNonCriticalField &&
                  criticalOrNonCriticalField.value &&
                  criticalOrNonCriticalField.value.value,
                global: Boolean(globalField && globalField.value),
              };
            });

          return mappedRecords && mappedRecords.length > 0
            ? {
                records: mappedRecords,
                toBeFetchedConsequences: unique(toBeFetchedConsequences),
              }
            : { records: [], toBeFetchedConsequences };
        }
      }
    }
  }

  // Fetching all preventative controls with causes

  async fetchPreventativeControlsWithCauses(
    bowtieConfiguration,
    preventativeControlsRecordLinkField,
    preventativeControls
  ) {
    let toBeFetchedCauses = [];
    if (
      preventativeControlsRecordLinkField &&
      preventativeControlsRecordLinkField.value &&
      preventativeControlsRecordLinkField.value.length > 0
    ) {
      const recordsPayload = await this.fetchRecords(
        bowtieConfiguration.forms?.controls?.id,
        preventativeControlsRecordLinkField.value
      );
      if (
        recordsPayload.success &&
        recordsPayload.payload &&
        recordsPayload.payload.data &&
        recordsPayload.payload.data.result &&
        recordsPayload.payload.data.result.results &&
        recordsPayload.payload.data.result.results.length > 0
      ) {
        const records = recordsPayload.payload.data.result.results;
        const causesFields = records
          .filter((record) => record.fields)
          .map((record) => {
            const causesRecordLinkField = record.fields.find(
              (field) => field.name === preventativeControls.causes.recordLinkField
            );
            if (causesRecordLinkField && causesRecordLinkField.value && causesRecordLinkField.value.length > 0) {
              toBeFetchedCauses = toBeFetchedCauses.concat(causesRecordLinkField.value);
              return {
                recordId: record.id,
                causeId: causesRecordLinkField.value,
              };
            } else {
              return { recordId: null, causeId: null };
            }
          });
        const causesRecordPayload = await this.fetchRecords(
          bowtieConfiguration.forms?.causes?.id,
          causesFields.map((field) => field.causeId)?.flat()
        );

        if (
          causesRecordPayload &&
          causesRecordPayload.success &&
          causesRecordPayload.payload &&
          causesRecordPayload.payload.data &&
          causesRecordPayload.payload.data.result &&
          causesRecordPayload.payload.data.result.results &&
          causesRecordPayload.payload.data.result.results.length > 0
        ) {
          const causes = causesRecordPayload.payload.data.result.results;
          const mappedCauses = causes
            .filter((cause) => cause.fields)
            .map((cause) => {
              const causesCaptionField = cause.fields.find(
                (field) => field.name === preventativeControls.causes.captionField
              );

              return {
                id: cause && cause.id && cause.id,
                uuid: cause && cause.id && cause.id,
                status: cause.status,
                value: causesCaptionField && causesCaptionField.value && causesCaptionField.value,
                linkUrl: cause && cause.linkUrl && cause.linkUrl.length > 0 ? cause.linkUrl : null,
              };
            });
          const mappedRecords = records
            .filter((record) => record.fields)
            .map((record) => {
              let effectiveOrNotEffectiveField = null;
              const captionField = record.fields.find((field) => field.name === preventativeControls.captionField);

              const criticalOrNonCriticalField = record.fields.find(
                (field) => field.name === preventativeControls.criticalOrNonCriticalField
              );

              if (
                preventativeControls.effectiveOrNotEffectiveField &&
                record[preventativeControls.effectiveOrNotEffectiveField]
              ) {
                effectiveOrNotEffectiveField = {
                  value: {
                    value: record[preventativeControls.effectiveOrNotEffectiveField],
                  },
                };
              } else {
                effectiveOrNotEffectiveField = record.fields.find(
                  (field) => field.name === preventativeControls.effectiveOrNotEffectiveField
                );
              }

              const recordCausesFields = causesFields.find((field) => field.recordId === record.id)?.causeId;

              const globalField = record.fields.find((field) => field.name === 'Global');

              return {
                id: record && record.id && record.id,
                uuid: record && record.id && record.id,
                value: captionField?.value,
                status: record.status,
                linkUrl: record.linkUrl && record.linkUrl.length > 0 ? record.linkUrl : null,
                causes: recordCausesFields
                  ? mappedCauses.filter((cause) => recordCausesFields.includes(String(cause.id)))
                  : [],
                effectiveOrNotEffective:
                  effectiveOrNotEffectiveField &&
                  effectiveOrNotEffectiveField.value &&
                  effectiveOrNotEffectiveField.value.value,
                criticalOrNonCritical:
                  criticalOrNonCriticalField &&
                  criticalOrNonCriticalField.value &&
                  criticalOrNonCriticalField.value.value,
                global: Boolean(globalField && globalField.value),
              };
            });

          return mappedRecords && mappedRecords.length > 0
            ? {
                records: mappedRecords,
                toBeFetchedCauses: unique(toBeFetchedCauses),
              }
            : { records: [], toBeFetchedCauses };
        }
      }
    }
  }

  doUpdateRecord(recordId, recordPayload) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${recordId}` }, {});

    return Request.doRequest({
      url: requestUrl,
      method: 'PUT',
      data: recordPayload,
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
    });
  }
}
