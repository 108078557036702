import React from 'react';
import GeneralContainer from '../general-container/general-container';
import MultiLineContainer from '../multi-line-container/multi-line-container';
import { palettes } from '../../../../environment/environment';
import { BowtieStateData } from '../../../../services/bowtie-data-types';

interface CausesExistingProps {
  bowtieData: BowtieStateData;
}

const CausesExisting = ({ bowtieData }: CausesExistingProps) => {
  return (
    <GeneralContainer
      title="Causes"
      id="causes-container"
      backgroundColor={palettes.lightBlue.primary}
      bowtieData={bowtieData}
    >
      <MultiLineContainer
        mode="single"
        id="causes"
        lines={bowtieData.causes}
        controlLines={bowtieData.causes}
        form={bowtieData.bowtieConfiguration.forms.causes}
        backgroundColor={palettes.lightBlue.primary}
        alignment="left"
      />
    </GeneralContainer>
  );
};

export default CausesExisting;
