import { configureStore } from '@reduxjs/toolkit';

import { api } from './api/api';
import { aiApi } from './api/aiApi';

import diagramReducer from './diagram/diagramReducer';
import filterReducer from './filter/filterReducer';
import userReducer from './user/userReducer';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [aiApi.reducerPath]: aiApi.reducer,
    diagram: diagramReducer,
    filter: filterReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(aiApi.middleware),
});

export default store;
