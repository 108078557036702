import { Button } from '@myosh/odin-components';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import recordSelectors from '../../../../modules/diagram/diagramSelectors';
import RecordServiceJS from '../../../../modules/diagram/diagramService';
import { useAppSelector } from '../../../../modules/hooks';
import {
  BowtieStateCauseData,
  BowtieStateConsequenceData,
  BowtieStateData,
} from '../../../../services/bowtie-data-types';
import { BowtieConfiguration } from '../../../../services/common-data-types';
import { DynamicFormSettings } from '../../../../services/form-data-types';
import { RecordResult } from '../../../../services/record-data-types';
import { RecordService } from '../../../../services/record-service';

const diagramService = new RecordServiceJS('test');
const recordService = new RecordService('test');

const SaveButton = () => {
  const [loading, setLoading] = useState(false);
  const bowtieData: BowtieStateData = useAppSelector(recordSelectors.selectBowtieData);

  const handleClick = async () => {
    setLoading(true);

    const riskScenarioRecordId = await toast.promise(saveAIGeneratedDiagram(bowtieData), {
      pending: 'Saving diagram, please wait...',
      success: {
        type: 'info',
        autoClose: 2000,
        render({ data }) {
          return data ? 'Diagram saved successfully' : 'Unable to save diagram';
        },
      },
      error: 'Unable to save diagram',
    });

    setLoading(false);

    if (riskScenarioRecordId) {
      // Reload the page and load the newly created bowtie diagram
      const storedSchema = localStorage.getItem('schema') ?? '';
      const params = new URLSearchParams({ schemaName: storedSchema, records: String(riskScenarioRecordId) });
      setTimeout(() => window.location.replace(`${window.location.origin}?${params}`), 2500);
    }
  };

  return (
    <Button onClick={handleClick} type="primary" disabled={loading}>
      {loading ? 'Saving...' : 'Save'}
    </Button>
  );
};

export default SaveButton;

// Save the AI generated diagram and returns the risk scenario recordId
const saveAIGeneratedDiagram = async (bowtieData: BowtieStateData) => {
  const { bowtieConfiguration, causes, consequences, scenario } = bowtieData;

  try {
    if (!bowtieConfiguration) {
      throw new Error('Unable to save diagram, bowtie configuration data is missing.');
    }

    const {
      scenario: { captionField: scenarioCaptionField } = {},
      forms: { main: { id: mainFormId, form: mainForm } = {} } = {},
    } = bowtieConfiguration;

    // Add the Risk Scenario record
    const riskScenarioRecordDefaultStatus =
      (mainForm as DynamicFormSettings).workflowSteps.find((step) => step.draft === true)?.label ?? 'Default';

    const riskScenarioResponse = await diagramService.addRiskScenario(
      mainForm,
      scenarioCaptionField,
      scenario,
      riskScenarioRecordDefaultStatus
    );

    if (!riskScenarioResponse.success) {
      throw new Error('Unable to save risk scenario');
    }

    const riskScenarioResponseSuccess = riskScenarioResponse.payload as AxiosResponse<{
      result: Array<RecordResult>;
    }>;

    const riskScenarioRecord = riskScenarioResponseSuccess.data.result[0];
    const riskScenarioRecordId = riskScenarioRecord.id!;

    // Add the Causes and the Preventative Controls records
    const preventativeControlRecordIdsPromises: Promise<Array<string>> = addCausesAndPreventativeControlsRecords(
      bowtieConfiguration,
      riskScenarioRecordId,
      causes
    );

    // Add the Consequences and the Mitigating Controls records
    const mitigatingControlRecordIdsPromises: Promise<Array<string>> = addConsequencesAndMitigatingControlsRecords(
      bowtieConfiguration,
      riskScenarioRecordId,
      consequences
    );

    const [preventativeControlRecordIdsPromisesResult, mitigatingControlRecordIdsPromisesResult] =
      await Promise.allSettled([preventativeControlRecordIdsPromises, mitigatingControlRecordIdsPromises]);

    // Update the risk scenario record with the control Ids
    let preventativeControlIds: Array<string> = [];
    if (
      preventativeControlRecordIdsPromisesResult.status === 'fulfilled' &&
      preventativeControlRecordIdsPromisesResult.value.length > 0
    ) {
      preventativeControlIds = preventativeControlRecordIdsPromisesResult.value;
    }

    let mitigatingControlIds: Array<string> = [];
    if (
      mitigatingControlRecordIdsPromisesResult.status === 'fulfilled' &&
      mitigatingControlRecordIdsPromisesResult.value.length > 0
    ) {
      mitigatingControlIds = mitigatingControlRecordIdsPromisesResult.value;
    }

    await recordService.setControlIdsToRiskScenarioRecord(
      riskScenarioRecordId,
      riskScenarioRecord.status,
      mainFormId!,
      preventativeControlIds,
      mitigatingControlIds
    );

    return riskScenarioRecordId;
  } catch (error) {
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      throw error;
    }
  }
};

const addCausesAndPreventativeControlsRecords = async (
  bowtieConfiguration: BowtieConfiguration,
  riskScenarioRecordId: number,
  causes?: BowtieStateCauseData[]
) => {
  const {
    forms: {
      causes: { id: causesFormId, moduleId: causesModuleId, form: causesForm } = {},
      controls: { id: controlsFormId, moduleId: controlsModuleId, form: controlsForm } = {},
    } = {},
    preventativeControls: {
      captionField: preventativeControlsCaptionField,
      causes: { captionField: preventativeControlsCausesCaptionField } = {},
    } = {},
  } = bowtieConfiguration;

  const preventativeControlRecordIds: Array<string> = [];

  if (causes && causes.length > 0) {
    const causeResponsePromises = causes.map((cause) => {
      // create the Cause record promise
      return recordService
        .addCauseConsequence(
          cause.value,
          causesFormId!,
          causesModuleId!,
          preventativeControlsCausesCaptionField!,
          causesForm!,
          riskScenarioRecordId
        )
        .then(async (causeResponse) => {
          if (causeResponse.success) {
            const causeResponseSuccess = causeResponse.payload as unknown as AxiosResponse<{
              result: Array<RecordResult>;
            }>;

            const causeRecordId = causeResponseSuccess.data.result[0].id!;

            // Add the Preventative Controls records
            if (cause.preventativeControls && cause.preventativeControls.length > 0) {
              const preventativeControlsRecordsPromises = cause.preventativeControls.map((preventativeControl) => {
                return recordService.addControl(
                  String(preventativeControl.id!),
                  preventativeControl.value,
                  controlsFormId!,
                  controlsModuleId!,
                  causeRecordId,
                  preventativeControlsCaptionField!,
                  controlsForm!,
                  true // preventative control
                );
              });

              const results = await Promise.allSettled(preventativeControlsRecordsPromises);

              results.forEach((preventativeControlRecordPromise) => {
                if (
                  preventativeControlRecordPromise.status === 'fulfilled' &&
                  preventativeControlRecordPromise.value.success
                ) {
                  const preventativeControlResponseSuccess = preventativeControlRecordPromise.value
                    .payload as unknown as AxiosResponse<{
                    result: Array<RecordResult>;
                  }>;

                  const preventativeControlRecordId = preventativeControlResponseSuccess.data.result[0].id!;

                  // the control Id is needed to update the risk scenario record
                  preventativeControlRecordIds.push(String(preventativeControlRecordId));
                }
              });
            }
          }
        });
    });

    await Promise.allSettled(causeResponsePromises);
  }

  return preventativeControlRecordIds;
};

const addConsequencesAndMitigatingControlsRecords = async (
  bowtieConfiguration: BowtieConfiguration,
  riskScenarioRecordId: number,
  consequences?: BowtieStateConsequenceData[]
) => {
  const {
    forms: {
      consequences: { id: consequencesFormId, moduleId: consequencesModuleId, form: consequencesForm } = {},
      controls: { id: controlsFormId, moduleId: controlsModuleId, form: controlsForm } = {},
    } = {},
    mitigatingControls: {
      captionField: mitigatingControlsCaptionField,
      consequences: { captionField: mitigatingControlsConsequencesCaptionField } = {},
    } = {},
  } = bowtieConfiguration;

  const mitigatingControlRecordIds: Array<string> = [];

  if (consequences && consequences.length > 0) {
    const consequenceResponsePromises = consequences.map((consequence) => {
      // create the Consequence record
      return recordService
        .addCauseConsequence(
          consequence.value,
          consequencesFormId!,
          consequencesModuleId!,
          mitigatingControlsConsequencesCaptionField!,
          consequencesForm!,
          riskScenarioRecordId
        )
        .then(async (consequenceResponse) => {
          if (consequenceResponse.success) {
            const consequenceResponseSuccess = consequenceResponse.payload as unknown as AxiosResponse<{
              result: Array<RecordResult>;
            }>;

            const consequenceRecordId = consequenceResponseSuccess.data.result[0].id!;

            // Add the Mitigating Controls records
            if (consequence.mitigatingControls && consequence.mitigatingControls.length > 0) {
              const mitigatingControlsRecordsPromises = consequence.mitigatingControls.map((mitigatingControl) => {
                return recordService.addControl(
                  String(mitigatingControl.id!),
                  mitigatingControl.value,
                  controlsFormId!,
                  controlsModuleId!,
                  consequenceRecordId,
                  mitigatingControlsCaptionField!,
                  controlsForm!,
                  false // mitigating control
                );
              });

              const results = await Promise.allSettled(mitigatingControlsRecordsPromises);
              results.forEach((mitigatingControlRecordPromise) => {
                if (
                  mitigatingControlRecordPromise.status === 'fulfilled' &&
                  mitigatingControlRecordPromise.value.success
                ) {
                  const mitigatingControlResponseSuccess = mitigatingControlRecordPromise.value
                    .payload as unknown as AxiosResponse<{
                    result: Array<RecordResult>;
                  }>;
                  const mitigatingControlRecordId = mitigatingControlResponseSuccess.data.result[0].id!;
                  // the control Id is needed to update the risk scenario record
                  mitigatingControlRecordIds.push(String(mitigatingControlRecordId));
                }
              });
            }
          }
        });
    });

    await Promise.allSettled(consequenceResponsePromises);
  }

  return mitigatingControlRecordIds;
};
