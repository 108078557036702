import React from 'react';
import GeneralContainer from '../general-container/general-container';
import { palettes } from '../../../../environment/environment';
import MultiLineContainer from '../multi-line-container/multi-line-container';
import { BowtieStateData, BowtieStateConsequenceData } from '../../../../services/bowtie-data-types';

interface MitigatingControlsExistingProps {
  bowtieData: BowtieStateData;
}

const MitigatingControlsExisting = ({ bowtieData }: MitigatingControlsExistingProps) => {
  const mitigatingControlsLines = bowtieData.consequences
    ? bowtieData.consequences.map((consequence: BowtieStateConsequenceData, index) => {
        return {
          id: `${consequence.uuid}_${index}`,
          childControls: consequence.mitigatingControls,
          parentId: consequence.uuid,
        };
      })
    : [];

  return (
    <GeneralContainer
      title="Mitigating Controls"
      backgroundColor={palettes.lightGray.background}
      id="mitigating-controls-container"
      bowtieData={bowtieData}
    >
      <MultiLineContainer
        mode="multi"
        controlLines={mitigatingControlsLines}
        form={bowtieData.bowtieConfiguration.forms.controls}
        backgroundColor={palettes.lightGray.background}
        id="mitigating_control"
        alignment="left"
      />
    </GeneralContainer>
  );
};

export default MitigatingControlsExisting;
