import React from 'react';
import { palettes } from '../../../../environment/environment';
import GeneralContainer from '../general-container/general-container';
import MultiLineContainer from '../multi-line-container/multi-line-container';
import { BowtieStateCauseData, BowtieStateData } from '../../../../services/bowtie-data-types';

interface PreventativeControlsExistingProps {
  bowtieData: BowtieStateData;
}

const PreventativeControlsExisting = ({ bowtieData }: PreventativeControlsExistingProps) => {
  const preventativeControlsLines = bowtieData.causes
    ? bowtieData.causes.map((cause: BowtieStateCauseData, index) => {
        return {
          id: `${cause.uuid}_${index}`,
          childControls: cause.preventativeControls,
          parentId: cause.uuid,
        };
      })
    : [];

  return (
    <GeneralContainer
      title="Preventative Controls"
      backgroundColor={palettes.lightGray.background}
      id="preventative-controls-container"
      bowtieData={bowtieData}
    >
      <MultiLineContainer
        mode="multi"
        controlLines={preventativeControlsLines}
        form={bowtieData.bowtieConfiguration.forms.controls}
        id="preventative_control"
        alignment="right"
        backgroundColor={palettes.lightGray.background}
      />
    </GeneralContainer>
  );
};

export default PreventativeControlsExisting;
