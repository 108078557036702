import { useUser } from '@myosh/myosh-login';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ReactZoomPanPinchRef,
  ReactZoomPanPinchState,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import GeneralErrorContainer from '../../components/Containers/GeneralErrorContainer/GeneralErrorContainer';
import Diagram from '../../components/diagram/diagram';
import LineSkeleton from '../../components/diagram/shared/line-skeleton/line-skeleton';
import Header from '../../components/layout/header/Header';
import { AIContextProvider } from '../../context/ai.context';
import { palettes, riskScenarioPageSize } from '../../environment/environment';
import { useLazyFactorsQuery } from '../../modules/api/aiApi';
import { useLazyMainFormConfigurationQuery } from '../../modules/api/api';
import diagramActions from '../../modules/diagram/diagramActions';
import diagramSelectors from '../../modules/diagram/diagramSelectors';
import RecordServiceJS from '../../modules/diagram/diagramService';
import {
  DIAGRAM_CONFIGURATION_MAIN_FAIL,
  DIAGRAM_CONFIGURATION_MAIN_SUCCESS,
  DIAGRAM_FETCH_RECORD_FAIL,
  DIAGRAM_FETCH_RECORD_SUCCESS,
  DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
} from '../../modules/diagram/diagramTypes';
import filterSelectors from '../../modules/filter/filterSelectors';
import { useAppDispatch, useAppSelector } from '../../modules/hooks';
import userActions from '../../modules/user/userActions';
import ZoomControls, { ZoomControlsLoading, ZoomControlsRef } from './components/zoom-controls';
import './Main.css';

const recordService = new RecordServiceJS('test');

export default function Main() {
  const [appTitle, setAppTitle] = useState<string>();
  const [initialDiagramScale, setInitialDiagramScale] = useState<number>();
  const [transformComponentMarginTop, setTransformComponentMarginTop] = useState<number>();
  const [transformComponentMarginLeft, setTransformComponentMarginLeft] = useState<number>();
  const [showHazard, setShowHazard] = useState(true);

  const makeHazardVisible = () => setShowHazard(true);

  const initialDiagramRef = useRef<HTMLDivElement>(null);
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null);
  const diagramRef = useRef<HTMLDivElement>(null);
  const zoomControlsRef = useRef<ZoomControlsRef>(null);
  const isAIGeneratedRef = useRef(false);

  const authUserState = useUser();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const diagramMode = useAppSelector(filterSelectors.selectDiagramMode);
  const queue = useAppSelector(diagramSelectors.selectQueues);
  const loading = useAppSelector(diagramSelectors.selectLoading);
  const errorMessage = useAppSelector(diagramSelectors.selectErrorMessage);
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);
  const records = useAppSelector(diagramSelectors.selectScenarioRecords);
  const diagramLayout = useAppSelector(diagramSelectors.selectDiagramLayout);
  const storedBowtieConfiguration = localStorage.getItem('bowtieConfiguration');

  // rtk query
  const [getFactors] = useLazyFactorsQuery();
  const [getMainFormConfiguration] = useLazyMainFormConfigurationQuery();

  useEffect(() => {
    if (bowtieData && bowtieData.scenario) {
      setAppTitle(bowtieData.scenario);
    } else {
      setAppTitle(undefined);
    }
  }, [bowtieData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (!queryParams?.get('records')) {
      setShowHazard(false);
    } else {
      setShowHazard(true);
    }
  }, []);

  useEffect(() => {
    if (queue?.tasks?.length > 0) {
      if (!queue.isQueuing) {
        dispatch(diagramActions.doUpdateQueueStatus(true));
        const { payload, type } = queue.tasks[0];

        if (isAIGeneratedRef.current === true) {
          if (type === 'preventative_control') {
            dispatch(diagramActions.addPreventativeControlsToAIDiagram(payload));
          } else {
            dispatch(diagramActions.addMitigatingControlsToAIDiagram(payload));
          }
        } else {
          if (type === 'preventative_control') {
            dispatch(diagramActions.doAddPreventativeControls(payload));
          } else {
            dispatch(diagramActions.doAddMitigatingControls(payload));
          }
        }
      }
    }
  }, [dispatch, queue?.tasks, queue?.isQueuing]);

  useEffect(() => {
    if (queue?.failed?.length > 0) {
      if (!queue.isRemovingQueue) {
        dispatch(diagramActions.doUpdateFailedQueueStatus(true));
        const { payload, type } = queue.failed[0];
        const { id, parentId } = payload;
        const data = {
          id,
          parentId,
        };

        if (type === 'preventative_control') {
          dispatch(diagramActions.doRemovePreventativeControl(data));
        } else {
          dispatch(diagramActions.doRemoveMitigatingControl(data));
        }
      }
    }
  }, [dispatch, queue?.failed, queue?.isRemovingQueue]);

  useEffect(() => {
    if (!!storedBowtieConfiguration && !!authUserState?.state?.user && dispatch) {
      dispatch(
        userActions.doFetchAuthenticatedUserAndSetPermissions(
          authUserState.state.user,
          JSON.parse(storedBowtieConfiguration).forms
        )
      );
    }
  }, [authUserState?.state?.user, dispatch, storedBowtieConfiguration]);

  useEffect(() => {
    if (initialDiagramRef?.current && !initialDiagramScale) {
      setInitialDiagramScale(window.innerWidth / initialDiagramRef.current.clientWidth - 0.06);
    }
  }, [initialDiagramRef, initialDiagramRef.current]);

  useEffect(() => {
    if (searchParams.size > 0) {
      // AI generated
      if (searchParams.has('term') && searchParams.has('risk')) {
        const term = searchParams.get('term')!;
        const risk = searchParams.get('risk')!;
        isAIGeneratedRef.current = true;
        const bowtieConfiguration = JSON.parse(localStorage.getItem('bowtieConfiguration') ?? '{}');

        getFactors({ term: term, selected_risk: risk })
          .unwrap()
          .then((data) => {
            // copy over the forms configuration
            const bowtieAIData = {
              bowtieConfiguration: bowtieConfiguration,
              causes: [...data.causes],
              consequences: [...data.consequences],
              scenario: risk,
              hazard: undefined,
            };

            // populate store with the AI data
            dispatch({
              type: DIAGRAM_FETCH_RECORD_SUCCESS,
              payload: bowtieAIData,
            });
          })
          .catch(() => {
            toast.error('Unable to generate bowtie, please try again later.');
          });
      } else if (searchParams.has('records')) {
        const recordId = searchParams.get('records')!;
        dispatch(diagramActions.doFetchAndTransformRecord(recordId));
      } else if (searchParams.has('formId')) {
        const formId = searchParams.get('formId')!;

        recordService
          .doFetchScenarioRecords({
            formId: formId,
            isNewRiskScenario: false,
          })
          .then(async (response) => {
            const { result } = await getMainFormConfiguration(Number(formId)).unwrap();

            dispatch({
              type: DIAGRAM_CONFIGURATION_MAIN_SUCCESS,
              payload: result,
            });

            const storedBowtieConfiguration = JSON.parse(localStorage.getItem('bowtieConfiguration') ?? '{}');

            const transformRecords = await recordService.doTransformScenarioRecords(
              response,
              storedBowtieConfiguration,
              result
            );

            const scenarioRecords = [
              {
                value: 0,
                label: 'New Diagram',
                selected: true,
                itemColor: '#fff',
              },
              ...transformRecords,
            ];

            dispatch({
              type: DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
              payload: {
                records: scenarioRecords,
                isLastPage: records?.length < riskScenarioPageSize,
              },
            });
          })
          .catch((err) => {
            dispatch({
              type: DIAGRAM_FETCH_RECORD_FAIL,
              payload: err?.message,
            });
            dispatch({
              type: DIAGRAM_CONFIGURATION_MAIN_FAIL,
            });
          });
      } else {
        dispatch({
          type: DIAGRAM_FETCH_RECORD_FAIL,
          payload: 'Parameter error',
        });
      }
    }
  }, [dispatch, searchParams, records?.length]);

  const setDiagramMargin = (state: ReactZoomPanPinchState) => {
    setTransformComponentMarginTop(state.positionY < 0 ? 0 - state.positionY : 0);
    setTransformComponentMarginLeft(state.positionX < 0 ? 0 - state.positionX : 0);
  };

  useEffect(() => {
    if (
      diagramLayout?.isWidth &&
      zoomControlsRef.current &&
      zoomControlsRef.current.getZoomPercentage() === 100 &&
      transformWrapperRef?.current &&
      diagramRef?.current &&
      window?.innerWidth
    ) {
      const transformScale = window.innerWidth / diagramRef.current.clientWidth - 0.06;

      setInitialDiagramScale(transformScale);
      transformWrapperRef.current.state.scale = transformScale;
      transformWrapperRef.current.centerView();
      setDiagramMargin(transformWrapperRef.current.state);
      dispatch(
        diagramActions.doUpdateDiagramLayout({
          isWidth: false,
        })
      );
    }
  }, [dispatch, diagramLayout?.isWidth]);

  return (
    <AIContextProvider isAIGenerated={isAIGeneratedRef.current}>
      <Helmet>
        <title>
          {appTitle && appTitle.length > 0 ? `${appTitle} | Bowtie by myosh` : 'New Diagram | Bowtie by myosh'}
        </title>
      </Helmet>
      <Header />

      {errorMessage && errorMessage.length > 0 ? (
        <GeneralErrorContainer errorMessage="An error occurred" errorDescription={errorMessage} />
      ) : (
        <>
          {loading ? (
            <>
              <ZoomControlsLoading />
              <div className="scale">
                <Diagram isLoading showHazard={showHazard} makeHazardVisible={makeHazardVisible} />
              </div>
            </>
          ) : initialDiagramScale ? (
            <TransformWrapper
              ref={transformWrapperRef}
              initialScale={initialDiagramScale}
              wheel={{ wheelDisabled: true, disabled: true }}
              minScale={0.1}
              maxScale={6}
              centerOnInit={true}
              doubleClick={{
                disabled: true,
              }}
              panning={{
                disabled: true,
                excluded: ['textarea', 'svg', 'main-area-container', 'hazard-container'],
              }}
              onZoomStop={(event) => {
                setDiagramMargin(event.state);
              }}
            >
              {({ zoomIn, zoomOut, resetTransform, state }) => {
                // if removed the zoom out doesn't always work correctly (we need to fix this)
                setTimeout(() => setDiagramMargin(state));

                // zoom control handlers
                const handleZoomIn = () => {
                  zoomIn();
                  setTimeout(() => {
                    setDiagramMargin(state);
                  }, 300);
                };

                const handleZoomOut = () => {
                  zoomOut();
                  setTimeout(() => {
                    setDiagramMargin(state);
                  }, 300);
                };

                const handleZoomReset = () => {
                  setTransformComponentMarginLeft(undefined);
                  setTransformComponentMarginTop(undefined);
                  setDiagramMargin(state);
                  resetTransform();
                };

                return (
                  <>
                    <ZoomControls
                      ref={zoomControlsRef}
                      zoomIn={handleZoomIn}
                      zoomOut={handleZoomOut}
                      zoomReset={handleZoomReset}
                    />
                    <TransformComponent
                      wrapperStyle={{
                        overflow: 'auto',
                        zIndex: 1000,
                        height: '80%',
                        width: '100%',
                        position: 'relative',
                      }}
                      wrapperClass="diagram-transform-container custom-scroll"
                      contentStyle={{
                        marginTop: transformComponentMarginTop ? `${transformComponentMarginTop}px` : 'unset',
                        marginLeft: transformComponentMarginLeft ? `${transformComponentMarginLeft}px` : 'unset',
                      }}
                    >
                      {bowtieData && bowtieData.scenario && (
                        <LineSkeleton
                          causes={bowtieData.causes}
                          consequences={bowtieData.consequences}
                          strokeColor={palettes.criticalControl.primary}
                          diagramMode={diagramMode}
                          showHazard={showHazard}
                        />
                      )}
                      <Diagram
                        ref={diagramRef}
                        isLoading={false}
                        showHazard={showHazard}
                        makeHazardVisible={makeHazardVisible}
                      />
                    </TransformComponent>
                  </>
                );
              }}
            </TransformWrapper>
          ) : (
            // This only exists to calculate the 'initialScale' for the <TransformWrapper> component
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                visibility: 'hidden',
              }}
            >
              <Diagram
                ref={initialDiagramRef}
                isLoading={false}
                showHazard={showHazard}
                makeHazardVisible={makeHazardVisible}
              />
            </div>
          )}
        </>
      )}
    </AIContextProvider>
  );
}
