import { LoginResult } from '@myosh/myosh-login/dist/types/components/login.component';
import { useMemo, useRef } from 'react';
import { Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import { LoginCallback, LogoutCallback, Login } from '@myosh/myosh-login';
import PrivateRoute from './shared/PrivateRoute';
import NoMatchRoute from './shared/NoMatchRoute';
import Main from '../../views/main/main';
import UsernameGuard from '../../guards/UsernameGuard';
import ParamGuard from '../guards/ParamGuard';

const GuardedMain = () => (
  <UsernameGuard>
    <Main />
  </UsernameGuard>
);

export default function RoutesComponent() {
  const usernameRef = useRef<string>();
  const { search } = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  if (search && !usernameRef.current) {
    const username = searchParams.get('userName') || searchParams.get('username');
    if (username) {
      usernameRef.current = username;
    }
  }

  const onLoginSuccess = (results: LoginResult) => {
    if (results) {
      localStorage.setItem('idToken', results.idToken);
      localStorage.setItem('refreshToken', results.refreshToken);
      localStorage.setItem('schema', results.selectedSchema);
    }

    const storedInitialParams = localStorage.getItem('initialParams');
    navigate(`/${storedInitialParams ? storedInitialParams : ''}`);
  };

  return (
    <>
      <Routes>
        <Route path="/auth/cb/logout" element={<LogoutCallback />} />
        <Route path="/auth/cb/silent" element={<div>Silent renewal callback</div>} />
        <Route path="/auth/cb" element={<LoginCallback />} />
        <Route path="/login" element={<Login onLoginSuccess={onLoginSuccess} username={usernameRef.current} />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route
            index
            element={
              <ParamGuard>
                <GuardedMain />
              </ParamGuard>
            }
          />
        </Route>
        <Route path="*" element={<NoMatchRoute />} />
      </Routes>
    </>
  );
}
