import React, { CSSProperties, useMemo } from 'react';
import { BowtieStateData } from '../../../../services/bowtie-data-types';
import './GeneralContainer.css';

const ids = {
  causes: 'causes-container',
  consequences: 'consequences-container',
  mitigatingControls: 'mitigating-controls-container',
  preventativeControls: 'preventative-controls-container',
};

interface GeneralContainerProps {
  title: string;
  backgroundColor: string;
  id: string;
  children: React.ReactNode;
  bowtieData: BowtieStateData;
}

export default function GeneralContainer({ title, backgroundColor, id, children, bowtieData }: GeneralContainerProps) {
  const hasDraftRecord = useMemo(() => {
    let generalForm = null;
    let records = null;

    switch (id) {
      case ids.causes: {
        generalForm = bowtieData?.bowtieConfiguration?.forms?.causes?.form;
        records = bowtieData?.causes ?? [];
        break;
      }
      case ids.consequences: {
        generalForm = bowtieData?.bowtieConfiguration?.forms?.consequences?.form;
        records = bowtieData?.consequences ?? [];
        break;
      }
      case ids.mitigatingControls: {
        generalForm = bowtieData?.bowtieConfiguration?.forms?.controls?.form;
        records = bowtieData?.consequences?.map((consequence) => consequence.mitigatingControls)?.flat() ?? [];
        break;
      }
      case ids.preventativeControls: {
        generalForm = bowtieData?.bowtieConfiguration?.forms?.controls?.form;
        records = bowtieData?.causes?.map((cause) => cause.preventativeControls)?.flat() ?? [];
        break;
      }
      default: {
        generalForm = null;
        records = null;
      }
    }

    const formWorkflowSteps = generalForm && generalForm.workflowSteps && generalForm.workflowSteps;

    const draftWorkflowStep = formWorkflowSteps && formWorkflowSteps.find((workflowStep) => workflowStep.draft);

    const draftWorkflowStepLabel = draftWorkflowStep && draftWorkflowStep.label;

    return (
      records &&
      draftWorkflowStepLabel &&
      records.some((record) => record && (record.status === draftWorkflowStepLabel || record.status === 'Draft'))
    );
  }, [bowtieData, id]);

  const generalContainerStyles: CSSProperties = useMemo(() => {
    return {
      padding: '18px 30px',
      fontFamily: 'Roboto',
      textAlign: 'center',
      backgroundColor,
      marginRight: '1rem',
      position: 'relative',
      color: '#333',
      height: 'max-content',
    };
  }, [backgroundColor]);

  return (
    <div className="general-area">
      <p className="draft-header" style={{ visibility: hasDraftRecord ? 'visible' : 'hidden' }}>
        Draft
      </p>
      <div className="general-container button-radius" id={id} style={{ ...generalContainerStyles }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: '4rem' }}>{title}</h4>
        {children}
      </div>
    </div>
  );
}
