import React, { useRef, Ref, forwardRef } from 'react';
import { useAppSelector } from '../../modules/hooks';
import recordSelectors from '../../modules/diagram/diagramSelectors';
import filterSelectors from '../../modules/filter/filterSelectors';
import { DIAGRAM_MODE } from '../../services/bowtie-data-types';
import { palettes } from '../../environment/environment';
import Hazard, { DiagramStatus } from './shared/hazard';
import RiskScenario from './shared/risk-scenario';
import Causes from './shared/causes/causes';
import CausesExisting from './shared/causes/causes-existing';
import Consequences from './shared/consequences/consequences';
import ConsequencesExisting from './shared/consequences/consequences-existing';
import PreventativeControls from './shared/preventative-controls/preventative-controls';
import PreventativeControlsExisting from './shared/preventative-controls/preventative-controls-existing';
import MitigatingControls from './shared/mitigating-controls/mitigating-controls';
import MitigatingControlsExisting from './shared/mitigating-controls/mitigating-controls-existing';
import { MainAreaContainer, HazardContainer, RiskScenarioContainer } from './shared/containers';
import './Diagram.css';

interface DiagramProps {
  isLoading: boolean;
  showHazard: boolean;
  makeHazardVisible: () => void;
}

const Diagram = ({ isLoading, showHazard, makeHazardVisible }: DiagramProps, diagramRef: Ref<HTMLDivElement>) => {
  const bowtieData = useAppSelector(recordSelectors.selectBowtieData);
  const diagramMode = useAppSelector(filterSelectors.selectDiagramMode);

  const riskScenarioInputRef = useRef<HTMLTextAreaElement>(null);
  const hazardInputRef = useRef<HTMLTextAreaElement>(null);

  const diagramRender = () => {
    // NEW bowtie (create mode)
    if (!bowtieData.scenario) {
      if (diagramMode === DIAGRAM_MODE.BOWTIE) {
        return (
          <>
            <Causes isLoading={isLoading} />
            <PreventativeControls isLoading={isLoading} color={palettes.gray.primary} />
            <MainAreaContainer>
              <HazardContainer>
                {showHazard && (
                  <Hazard
                    ref={hazardInputRef}
                    hazard={bowtieData.hazard}
                    isLoading={isLoading}
                    mode={DiagramStatus.NEW}
                  />
                )}
              </HazardContainer>
              <RiskScenarioContainer>
                <RiskScenario
                  ref={riskScenarioInputRef}
                  mode={DiagramStatus.NEW}
                  isLoading={isLoading}
                  showHazard={makeHazardVisible}
                  hazardInputRef={hazardInputRef}
                  bowtieData={bowtieData}
                />
              </RiskScenarioContainer>
            </MainAreaContainer>
            <MitigatingControls isLoading={isLoading} color={palettes.gray.primary} />
            <Consequences isLoading={isLoading} />
          </>
        );
      } else {
        // DIAGRAM_MODE.BUTTERFLY
        return (
          <>
            <PreventativeControls isLoading={isLoading} color={palettes.gray.primary} />
            <Causes isLoading={isLoading} />
            <MainAreaContainer>
              <HazardContainer>
                {showHazard && (
                  <Hazard
                    ref={hazardInputRef}
                    hazard={bowtieData.hazard}
                    isLoading={isLoading}
                    mode={DiagramStatus.NEW}
                  />
                )}
              </HazardContainer>
              <RiskScenarioContainer>
                <RiskScenario
                  ref={riskScenarioInputRef}
                  mode={DiagramStatus.NEW}
                  isLoading={isLoading}
                  showHazard={makeHazardVisible}
                  hazardInputRef={hazardInputRef}
                />
              </RiskScenarioContainer>
            </MainAreaContainer>
            <Consequences isLoading={isLoading} />
            <MitigatingControls isLoading={isLoading} color={palettes.gray.primary} />
          </>
        );
      }
    }

    // EXISTING bowtie (edit mode)
    if (diagramMode === DIAGRAM_MODE.BOWTIE) {
      return (
        <>
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <CausesExisting bowtieData={bowtieData} />
          ) : (
            <Causes isLoading={isLoading} />
          )}
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <PreventativeControlsExisting bowtieData={bowtieData} />
          ) : (
            <PreventativeControls isLoading={isLoading} color={palettes.gray.primary} />
          )}
          <MainAreaContainer>
            <HazardContainer>
              {showHazard && (
                <Hazard
                  ref={hazardInputRef}
                  hazard={bowtieData.hazard}
                  isLoading={isLoading}
                  mode={DiagramStatus.UPDATE}
                />
              )}
            </HazardContainer>
            <RiskScenarioContainer>
              <RiskScenario
                ref={riskScenarioInputRef}
                mode={DiagramStatus.UPDATE}
                isLoading={isLoading}
                defaultRiskScenario={bowtieData.scenario}
                hasCircleRight={Boolean(bowtieData.consequences)}
                hasCircleLeft={Boolean(bowtieData.causes)}
                link={bowtieData.scenarioRecord?.linkUrl}
                showHazard={makeHazardVisible}
                hazardInputRef={hazardInputRef}
                bowtieData={bowtieData}
              />
            </RiskScenarioContainer>
          </MainAreaContainer>
          {bowtieData && bowtieData.consequences && bowtieData.consequences.length > 0 ? (
            <MitigatingControlsExisting bowtieData={bowtieData} />
          ) : (
            <MitigatingControls isLoading={isLoading} color={palettes.gray.primary} />
          )}
          {bowtieData && bowtieData.consequences && bowtieData.consequences.length > 0 ? (
            <ConsequencesExisting bowtieData={bowtieData} />
          ) : (
            <Consequences isLoading={isLoading} />
          )}
        </>
      );
    } else {
      // DIAGRAM_MODE.BUTTERFLY
      return (
        <>
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <PreventativeControlsExisting bowtieData={bowtieData} />
          ) : (
            <PreventativeControls isLoading={isLoading} color="#B6B6B6" />
          )}
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <CausesExisting bowtieData={bowtieData} />
          ) : (
            <Causes isLoading={isLoading} />
          )}
          <MainAreaContainer>
            <HazardContainer>
              {showHazard && (
                <Hazard
                  ref={hazardInputRef}
                  hazard={bowtieData.hazard}
                  isLoading={isLoading}
                  mode={DiagramStatus.UPDATE}
                />
              )}
            </HazardContainer>
            <RiskScenarioContainer>
              <RiskScenario
                ref={riskScenarioInputRef}
                mode={DiagramStatus.UPDATE}
                isLoading={isLoading}
                defaultRiskScenario={bowtieData.scenario}
                hasCircleRight={Boolean(bowtieData.consequences)}
                hasCircleLeft={Boolean(bowtieData.causes)}
                link={bowtieData.scenarioRecord?.linkUrl}
                showHazard={makeHazardVisible}
                hazardInputRef={hazardInputRef}
              />
            </RiskScenarioContainer>
          </MainAreaContainer>
          {bowtieData && bowtieData.consequences && bowtieData.consequences.length > 0 ? (
            <ConsequencesExisting bowtieData={bowtieData} />
          ) : (
            <Consequences isLoading={isLoading} />
          )}
          {bowtieData && bowtieData.consequences && bowtieData.consequences.length > 0 ? (
            <MitigatingControlsExisting bowtieData={bowtieData} />
          ) : (
            <MitigatingControls isLoading={isLoading} color="#B6B6B6" />
          )}
        </>
      );
    }
  };

  return (
    <div
      className="diagram-container"
      id="diagram"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        margin: 'auto',
        position: 'relative',
      }}
      ref={diagramRef}
    >
      {diagramRender()}
    </div>
  );
};

export default forwardRef(Diagram);
