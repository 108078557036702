import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import Button from '../../../../views/shared/components/button.component';
import diagramActions from '../../../../modules/diagram/diagramActions';
import userSelectors from '../../../../modules/user/userSelectors';
import './MultiLineContainer.css';
import SingleElementLine from './single-element-line';
import MultiElementLine, { MultiElementLineType } from './multi-element-line';
import { BowtieStateCauseData, BowtieStateConsequenceData } from '../../../../services/bowtie-data-types';
import { BowtieModuleForm } from '../../../../services/common-data-types';

const MULTI_LINE_CONTAINER_MODE = {
  SINGLE: 'single',
  MULTI: 'multi',
};

export const CONTAINER_ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
};

interface MultiLineContainerProps {
  mode: string;
  lines?: Array<BowtieStateCauseData> | Array<BowtieStateConsequenceData>;
  id: string;
  alignment: string;
  backgroundColor: string;
  controlLines?: Array<BowtieStateCauseData> | Array<BowtieStateConsequenceData> | Array<MultiElementLineType>;
  form: BowtieModuleForm;
}

export default function MultiLineContainer({
  mode,
  controlLines,
  alignment,
  id,
  backgroundColor,
  form,
}: MultiLineContainerProps) {
  const dispatch = useAppDispatch();

  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  function buttonClicked() {
    if (id === 'causes') {
      dispatch(diagramActions.doAddCauseInput());
    } else {
      dispatch(diagramActions.doAddConsequenceInput());
    }
  }

  const renderContainer = () => {
    if (mode === MULTI_LINE_CONTAINER_MODE.SINGLE) {
      return (
        controlLines &&
        controlLines.length > 0 &&
        controlLines.map((line, index) => {
          return (
            <SingleElementLine
              key={`${line.id}_${index}`}
              backgroundColor={backgroundColor}
              line={line as BowtieStateCauseData | BowtieStateConsequenceData}
              index={index}
              id={id}
            />
          );
        })
      );
    }

    if (mode === MULTI_LINE_CONTAINER_MODE.MULTI) {
      return (
        controlLines &&
        controlLines.length > 0 &&
        controlLines.map((line, index) => (
          <MultiElementLine
            key={`${line.id}_${index}`}
            alignment={alignment}
            multiLines={line as MultiElementLineType}
            id={id}
            index={index}
            form={form}
          />
        ))
      );
    }
    return <div />;
  };

  return (
    <div
      className="multi-line-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: mode === MULTI_LINE_CONTAINER_MODE.MULTI ? '-3rem' : '0',
      }}
    >
      {renderContainer()}
      {mode === MULTI_LINE_CONTAINER_MODE.SINGLE &&
        userWithPermissions &&
        userWithPermissions.permissions &&
        userWithPermissions.permissions.createRecord &&
        userWithPermissions.permissions.createRecord[`${id}`] && (
          <Button text={`Add ${id}`} buttonClicked={buttonClicked} />
        )}
    </div>
  );
}
