import { Button, ModalDialog } from '@myosh/odin-components';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useLazySuggestQuery } from '../../../../modules/api/aiApi';
import diagramSelectors from '../../../../modules/diagram/diagramSelectors';
import { useAppSelector } from '../../../../modules/hooks';
import './ai-analysis-suggestion.css';
import {
  BowtieCauseRecord,
  BowtieConsequenceRecord,
  BowtieStateCauseData,
  BowtieStateConsequenceData,
  BowtieStateData,
} from '../../../../services/bowtie-data-types';
import { BowtieCorrectionRequest } from '../../../../@types/suggest.ai-api.types';
import { useLocation } from 'react-router-dom';

enum ControlCategory {
  SOFT = 'Soft',
  HARD = 'Hard',
}

const generateSuggestPayload = (bowtieData: BowtieStateData, term?: string): BowtieCorrectionRequest => {
  const causes = bowtieData?.causes?.map((cause: BowtieStateCauseData) => {
    return {
      id: String(cause.id),
      name: cause.value,
      controls: cause.preventativeControls.map((preventativeControl: BowtieCauseRecord) => {
        return {
          id: String(preventativeControl.id),
          isCritical: preventativeControl.criticalOrNonCritical !== 'Non Critical',
          isGlobal: preventativeControl.global as boolean,
          name: preventativeControl.value,
          //TODO: change with real data
          type: '',
          isInitial: false,
          category: ControlCategory.SOFT,
        };
      }),
    };
  });

  const consequences = bowtieData?.consequences?.map((consequence: BowtieStateConsequenceData) => {
    return {
      id: String(consequence.id),
      name: consequence.value,
      controls: consequence.mitigatingControls.map((mitigatingControl: BowtieConsequenceRecord) => {
        return {
          id: String(mitigatingControl.id),
          isCritical: mitigatingControl.criticalOrNonCritical !== 'Non Critical',
          isGlobal: mitigatingControl.global as boolean,
          name: mitigatingControl.value,
          //TODO: change with real data
          type: '',
          isInitial: false,
          category: ControlCategory.SOFT,
        };
      }),
    };
  });

  return {
    bowtie: {
      risk: bowtieData.scenario,
      causes: causes || [],
      consequences: consequences || [],
      //TODO: change with real data
      term: term ?? '',
    },
    control_types: [''],
  };
};

function AIAnalysisSuggestion() {
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [getSuggestion, getSuggestionResult] = useLazySuggestQuery();
  const [showAIAnalysisModal, setShowAIAnalysisModal] = useState(false);
  const isLoading = getSuggestionResult?.isFetching;

  const handleBowtieAIAnalysisClick = useCallback(() => {
    getSuggestion(generateSuggestPayload(bowtieData, searchParams.get('term') as string))
      .then((response) => {
        if (response?.status === QueryStatus.fulfilled) {
          setShowAIAnalysisModal(true);
        } else {
          toast.error('AI Analysis Error Occured, Please Try Again.');
        }
      })
      .catch(() => {
        toast.error('AI Analysis Error Occured, Please Try Again.');
      });
  }, [bowtieData]);

  return (
    <>
      <Button disabled={isLoading} onClick={handleBowtieAIAnalysisClick} loading={isLoading}>
        <p style={{ marginLeft: isLoading ? '4px' : '0px' }}>AI Analysis</p>
      </Button>
      <ModalDialog
        shouldCheckZIndex={true}
        visible={showAIAnalysisModal}
        header={''}
        hidden={() => setShowAIAnalysisModal(false)}
      >
        <div className="ai-analysis-suggestion-container">
          <label className="ai-analysis-suggestion-label">AI Analysis</label>
          <hr className="ai-analysis-suggestion-hr" />
          <div className="ai-analysis-suggestion-content">
            <p>{getSuggestionResult?.data?.suggestion_text}</p>
          </div>
        </div>
      </ModalDialog>
    </>
  );
}

export default AIAnalysisSuggestion;
