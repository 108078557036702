import React, { useEffect, useState } from 'react';
import { BowtieStateCauseData, BowtieStateConsequenceData, DIAGRAM_MODE } from '../../../../services/bowtie-data-types';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import diagramActions from '../../../../modules/diagram/diagramActions';
import userSelectors from '../../../../modules/user/userSelectors';
import { palettes } from '../../../../environment/environment';
import DiagramLine, { DiagramLineDirection } from '../../diagram-line/diagram-line.component';

interface LineSkeletonProps {
  causes?: Array<BowtieStateCauseData>;
  consequences?: Array<BowtieStateConsequenceData>;
  diagramMode: DIAGRAM_MODE;
  strokeColor: string;
  showHazard: boolean;
}

export default function LineSkeleton({
  causes,
  consequences,
  strokeColor,
  diagramMode,
  showHazard,
}: LineSkeletonProps) {
  const [lineStroke, setLineStroke] = useState(1.5);
  const [activeLine, setActiveLine] = useState<string>();
  const [headerDimensions, setHeaderDimensions] = useState<DOMRect>();
  const [diagramDimensions, setDiagramDimensions] = useState<DOMRect>();
  const [zoomControlDimensions, setZoomControlDimensions] = useState<DOMRect>();
  const [causesContainerDimensions, setCausesContainerDimensions] = useState<DOMRect>();
  const [consequencesContainerDimensions, setConsequencesContainerDimensions] = useState<DOMRect>();
  const [preventativeControlContainerDimensions, setPreventativeControlContainerDimensions] = useState<DOMRect>();
  const [mitigatingControlContainerDimensions, setMitigatingControlContainerDimensions] = useState<DOMRect>();
  const [causesDimensions, setCausesDimensions] = useState<Array<number>>();
  const [consequencesDimensions, setConsequencesDimensions] = useState<Array<number>>();
  const [riskScenarioDimensions, setRiskScenarioDimensions] = useState<RectangleDimensions>();
  const [hazardDimensions, setHazardDimensions] = useState<RectangleDimensions>();

  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  useEffect(() => {
    const mainAreaContainer = document.querySelector('.diagram-transform-container');

    return () => {
      mainAreaContainer?.removeEventListener('scroll', () => {});
    };
  }, []);

  useEffect(() => {
    calculateLineSkeletonDimensions({
      causes,
      consequences,
      showHazard,
      setHeaderDimensions,
      setDiagramDimensions,
      setZoomControlDimensions,
      setCausesContainerDimensions,
      setConsequencesContainerDimensions,
      setPreventativeControlContainerDimensions,
      setMitigatingControlContainerDimensions,
      setCausesDimensions,
      setConsequencesDimensions,
      setRiskScenarioDimensions,
      setHazardDimensions,
    });
  }, [diagramMode, causes, consequences]);

  const dispatch = useAppDispatch();

  return (
    <svg
      height="100%"
      width="100%"
      style={{
        position: 'absolute',
        zIndex: 2000,
        top: 0,
        left: 0,
      }}
    >
      {diagramDimensions && riskScenarioDimensions && hazardDimensions && zoomControlDimensions && headerDimensions && (
        <>
          <line
            stroke={strokeColor}
            x1={diagramMode === DIAGRAM_MODE.BUTTERFLY ? hazardDimensions.x + 223 : hazardDimensions.x}
            y1={hazardDimensions.y}
            x2={diagramMode === DIAGRAM_MODE.BUTTERFLY ? riskScenarioDimensions.x + 223 : riskScenarioDimensions.x}
            y2={riskScenarioDimensions.y}
          />
        </>
      )}

      {/* Butterfly configuration */}
      {diagramMode === DIAGRAM_MODE.BUTTERFLY &&
        causesContainerDimensions &&
        riskScenarioDimensions &&
        hazardDimensions &&
        headerDimensions &&
        zoomControlDimensions &&
        causes &&
        causesDimensions &&
        causesDimensions.length > 0 &&
        causesDimensions.map((_, index) => {
          const riskScenario = document.getElementById('risk-scenario');
          const preventativeControlsContainer = document.getElementById('preventative-controls-container');
          const causesContainer = document.getElementById('causes-container');
          const causeElement = document.getElementById('causes_' + (index + 1));

          const hasNeededDomElements = riskScenario && preventativeControlsContainer && causesContainer && causeElement;

          return (
            hasNeededDomElements && (
              <g
                strokeWidth={activeLine && activeLine === 'causes' + (index + 1) ? lineStroke : '1.5'}
                style={{
                  transition: '100ms',
                }}
                key={index}
                stroke={
                  activeLine && activeLine === 'causes' + (index + 1)
                    ? palettes.criticalControl.rgb[8]
                    : palettes.criticalControl.primary
                }
                strokeDasharray={activeLine && activeLine === 'causes' + (index + 1) ? '8' : undefined}
              >
                <line
                  x1={0}
                  y1={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
                  x2={causesContainer.offsetLeft + causesContainer.clientWidth}
                  y2={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
                  stroke={strokeColor}
                />
                <line
                  x1={causesContainer.clientWidth + causesContainer.offsetLeft}
                  y1={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
                  x2={riskScenario.offsetLeft + (riskScenario.offsetParent as HTMLElement)?.offsetLeft ?? 0}
                  y2={riskScenario.offsetTop + riskScenario.clientHeight / 2}
                  stroke={strokeColor}
                />
                {activeLine && activeLine === 'causes' + (index + 1) && (
                  <AddPlus
                    x1={preventativeControlsContainer.clientWidth / 2}
                    y1={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
                    onMouseEnter={() => {
                      setActiveLine('causes' + (index + 1));
                      setLineStroke(4);
                    }}
                    onMouseLeave={() => {
                      setLineStroke(1.5);
                      setActiveLine(undefined);
                    }}
                  />
                )}
                {userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls && (
                    <line
                      onClick={() => dispatch(diagramActions.doAddPreventativeControlInput({ index, value: null }))}
                      onMouseEnter={() => {
                        setActiveLine('causes' + (index + 1));
                        setLineStroke(4);
                      }}
                      onMouseLeave={() => {
                        setLineStroke(1.5);
                        setActiveLine(undefined);
                      }}
                      style={{
                        height: '100px',
                        cursor: activeLine === 'causes' + (index + 1) ? 'pointer' : 'auto',
                      }}
                      x1={0}
                      y1={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
                      x2={causesContainer.offsetLeft + causesContainer.clientWidth}
                      y2={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
                      stroke="transparent"
                      strokeDasharray="0"
                      strokeWidth="40"
                    ></line>
                  )}
              </g>
            )
          );
        })}

      {diagramMode === DIAGRAM_MODE.BUTTERFLY &&
        consequencesContainerDimensions &&
        riskScenarioDimensions &&
        consequences &&
        consequencesDimensions &&
        consequencesDimensions.length > 0 &&
        consequencesDimensions.map((_, index) => {
          const riskScenario = document.getElementById('risk-scenario');
          const mitigatingControlsContainer = document.getElementById('mitigating-controls-container');
          const consequencesContainer = document.getElementById('consequences-container');
          const consequenceElement = document.getElementById('consequences_' + (index + 1));

          const hasNeededDomElements =
            riskScenario && mitigatingControlsContainer && consequencesContainer && consequenceElement;

          return (
            hasNeededDomElements && (
              <g
                strokeWidth={activeLine && activeLine === 'consequences' + (index + 1) ? lineStroke : '1.5'}
                style={{
                  transition: '100ms',
                }}
                key={index}
                stroke={
                  activeLine && activeLine === 'consequences' + (index + 1)
                    ? palettes.criticalControl.rgb[8]
                    : palettes.criticalControl.primary
                }
                strokeDasharray={activeLine && activeLine === 'consequences' + (index + 1) ? '8' : undefined}
              >
                <line
                  x1={mitigatingControlsContainer.offsetLeft + mitigatingControlsContainer.clientWidth}
                  y1={consequenceElement && consequenceElement.offsetTop + consequenceElement.clientHeight / 2 + 30}
                  x2={consequencesContainer.offsetLeft}
                  y2={consequenceElement && consequenceElement.offsetTop + consequenceElement.clientHeight / 2 + 30}
                  stroke={strokeColor}
                ></line>
                <line
                  x1={consequencesContainer.offsetLeft}
                  y1={consequenceElement && consequenceElement.offsetTop + consequenceElement.clientHeight / 2 + 30}
                  x2={
                    riskScenario.offsetLeft +
                    ((riskScenario.offsetParent as HTMLElement)?.offsetLeft ?? 0) +
                    riskScenario.clientWidth +
                    5
                  }
                  y2={riskScenario.offsetTop + riskScenario.clientHeight / 2}
                  stroke={strokeColor}
                ></line>
                {activeLine && activeLine === 'consequences' + (index + 1) && (
                  <AddPlus
                    x1={mitigatingControlsContainer.offsetLeft + mitigatingControlsContainer.clientWidth / 2}
                    y1={consequenceElement && consequenceElement.offsetTop + consequenceElement.clientHeight / 2 + 30}
                    onMouseEnter={() => {
                      setActiveLine('causes' + (index + 1));
                      setLineStroke(4);
                    }}
                    onMouseLeave={() => {
                      setLineStroke(1.5);
                      setActiveLine(undefined);
                    }}
                  />
                )}
                {userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls && (
                    <line
                      onClick={() => dispatch(diagramActions.doAddMitigatingControlInput({ index, value: null }))}
                      onMouseEnter={() => {
                        setActiveLine('consequences' + (index + 1));
                        setLineStroke(4);
                      }}
                      onMouseLeave={() => {
                        setLineStroke(1.5);
                        setActiveLine(undefined);
                      }}
                      style={{
                        height: '100px',
                        cursor: activeLine === 'consequences' + (index + 1) ? 'pointer' : 'auto',
                      }}
                      x1={mitigatingControlsContainer.offsetLeft + mitigatingControlsContainer.clientWidth}
                      y1={consequenceElement && consequenceElement.offsetTop + consequenceElement.clientHeight / 2 + 30}
                      x2={consequencesContainer.offsetLeft}
                      y2={consequenceElement && consequenceElement.offsetTop + consequenceElement.clientHeight / 2 + 30}
                      stroke="transparent"
                      strokeDasharray="0"
                      strokeWidth="40"
                    ></line>
                  )}
              </g>
            )
          );
        })}

      {/* Bowtie configuration */}

      {diagramMode === DIAGRAM_MODE.BOWTIE &&
        preventativeControlContainerDimensions &&
        riskScenarioDimensions &&
        causesDimensions &&
        causesDimensions.length > 0 &&
        causesDimensions.map((_, index) => {
          const causeElement = document.getElementById('causes_' + (index + 1));
          const preventativeControlsContainer = document.getElementById('preventative-controls-container');
          const riskScenario = document.getElementById('risk-scenario');

          const hasNeededDomElements = causeElement && preventativeControlsContainer && riskScenario;

          return (
            hasNeededDomElements && (
              <DiagramLine
                key={`cause_${index}`}
                userHasPermissions={
                  userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls
                }
                startItem={causeElement}
                controlContainer={preventativeControlsContainer}
                endItem={riskScenario}
                onClick={() => dispatch(diagramActions.doAddPreventativeControlInput({ index, value: null }))}
              />
            )
          );
        })}

      {diagramMode === DIAGRAM_MODE.BOWTIE &&
        mitigatingControlContainerDimensions &&
        riskScenarioDimensions &&
        consequences &&
        consequencesDimensions &&
        consequencesDimensions.length > 0 &&
        consequencesDimensions.map((_, index) => {
          const consequenceElement = document.getElementById('consequences_' + (index + 1));
          const mitigatingControlsContainer = document.getElementById('mitigating-controls-container');
          const riskScenario = document.getElementById('risk-scenario');

          const hasNeededDomElements = consequenceElement && mitigatingControlsContainer && riskScenario;

          return (
            hasNeededDomElements && (
              <DiagramLine
                key={`consequence_${index}`}
                direction={DiagramLineDirection.RightToLeft}
                userHasPermissions={
                  userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls
                }
                startItem={consequenceElement}
                controlContainer={mitigatingControlsContainer}
                endItem={riskScenario}
                onClick={() => dispatch(diagramActions.doAddMitigatingControlInput({ index, value: null }))}
              />
            )
          );
        })}
    </svg>
  );
}

// AddPlus internal component
interface AddPlusProps {
  x1: number;
  y1: number;
  onMouseEnter: React.MouseEventHandler<SVGCircleElement>;
  onMouseLeave: React.MouseEventHandler<SVGCircleElement>;
}

function AddPlus({ x1, y1, onMouseEnter, onMouseLeave }: AddPlusProps) {
  return (
    <>
      <circle
        cx={x1}
        cy={y1}
        r="10"
        fill="white"
        stroke={palettes.criticalControl.rgb[8]}
        strokeWidth="3"
        style={{
          position: 'relative',
          zIndex: '5000 !important',
          cursor: 'pointer',
        }}
        strokeDasharray="0"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <line
        x1={x1}
        y1={y1 - 7}
        x2={x1}
        y2={y1 + 7}
        stroke={palettes.criticalControl.rgb[8]}
        strokeWidth="3"
        style={{
          position: 'relative',
          zIndex: '5000 !important',
          cursor: 'pointer',
        }}
        strokeDasharray="0"
      />
      <line
        x1={x1 - 7}
        y1={y1}
        x2={x1 + 7}
        y2={y1}
        stroke={palettes.criticalControl.rgb[8]}
        strokeWidth="3"
        style={{
          position: 'relative',
          zIndex: '5000 !important',
          cursor: 'pointer',
        }}
        strokeDasharray="0"
      />
    </>
  );
}

// Util
interface RectangleDimensions {
  x: number;
  y: number;
}

interface CalculateLineSkeletonDimensionsProps {
  causes?: Array<BowtieStateCauseData>;
  consequences?: Array<BowtieStateConsequenceData>;
  showHazard: boolean;
  setHeaderDimensions: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setDiagramDimensions: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setZoomControlDimensions: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setCausesContainerDimensions: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setConsequencesContainerDimensions: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setPreventativeControlContainerDimensions: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setMitigatingControlContainerDimensions: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setCausesDimensions: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setConsequencesDimensions: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setRiskScenarioDimensions: React.Dispatch<React.SetStateAction<RectangleDimensions | undefined>>;
  setHazardDimensions: React.Dispatch<React.SetStateAction<RectangleDimensions | undefined>>;
}

function calculateLineSkeletonDimensions({
  causes,
  consequences,
  showHazard,
  setHeaderDimensions,
  setDiagramDimensions,
  setZoomControlDimensions,
  setCausesContainerDimensions,
  setConsequencesContainerDimensions,
  setPreventativeControlContainerDimensions,
  setMitigatingControlContainerDimensions,
  setCausesDimensions,
  setConsequencesDimensions,
  setRiskScenarioDimensions,
  setHazardDimensions,
}: CalculateLineSkeletonDimensionsProps) {
  const causesContainer = document.getElementById('causes-container');
  const header = document.getElementById('header');
  const diagram = document.getElementById('diagram');
  const consequencesContainer = document.getElementById('consequences-container');
  const preventativeControlsContainer = document.getElementById('preventative-controls-container');
  const mitigatingControlContainer = document.getElementById('mitigating-controls-container');

  const riskScenario = document.getElementById('risk-scenario');

  const hazard = showHazard ? document.getElementById('hazard') : null;

  const zoomControlContainer = document.getElementById('diagram-zoom-controls');

  if (header) {
    setHeaderDimensions(header.getBoundingClientRect());
  }

  if (causesContainer) {
    setCausesContainerDimensions(causesContainer.getBoundingClientRect());
  }

  if (consequencesContainer) {
    setConsequencesContainerDimensions(consequencesContainer.getBoundingClientRect());
  }

  if (preventativeControlsContainer) {
    setPreventativeControlContainerDimensions(preventativeControlsContainer.getBoundingClientRect());
  }

  if (mitigatingControlContainer) {
    setMitigatingControlContainerDimensions(mitigatingControlContainer.getBoundingClientRect());
  }

  if (riskScenario && preventativeControlsContainer) {
    setRiskScenarioDimensions({
      x:
        riskScenario.offsetLeft +
        preventativeControlsContainer.clientWidth +
        preventativeControlsContainer.offsetLeft +
        riskScenario.clientWidth / 2 +
        18,
      y: riskScenario.offsetTop - 10,
    });
  }

  if (showHazard && hazard && preventativeControlsContainer) {
    setHazardDimensions({
      x:
        (hazard.offsetParent as HTMLElement).offsetLeft +
        preventativeControlsContainer.clientWidth +
        preventativeControlsContainer.offsetLeft +
        hazard.clientWidth / 2 +
        18,
      y: hazard.clientHeight + 10,
    });
  }

  if (diagram) {
    setDiagramDimensions(diagram.getBoundingClientRect());
  }

  if (zoomControlContainer) {
    setZoomControlDimensions(zoomControlContainer.getBoundingClientRect());
  }

  if (causes) {
    const causesDimensions: Array<number> = [];

    causes.forEach((_, index) => {
      const causeDOMElement = document.getElementById(`causes_${index + 1}`);

      if (causeDOMElement) {
        causesDimensions.push(index);
      }
    });
    setCausesDimensions(causesDimensions);
  }

  if (consequences) {
    const consequencesDimensions: Array<number> = [];

    consequences.forEach((_, index) => {
      const consequenceDOMElement = document.getElementById(`consequences_${index + 1}`);
      if (consequenceDOMElement) {
        consequencesDimensions.push(index);
      }
    });
    setConsequencesDimensions(consequencesDimensions);
  }
}
