import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Rectangle from './rectangle/rectangle';
import { hazardAndRiskDefaultStyle, palettes } from '../../../environment/environment';
import LoadingPreview from './loading-preview';
import { useAppDispatch, useAppSelector } from '../../../modules/hooks';
import userSelectors from '../../../modules/user/userSelectors';
import diagramActions from '../../../modules/diagram/diagramActions';

export enum DiagramStatus {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
}

interface HazardProps {
  hazard?: string;
  isLoading: boolean;
  mode: DiagramStatus;
}

const Hazard = ({ hazard, mode, isLoading = false }: HazardProps, forwardRef: Ref<HTMLTextAreaElement>) => {
  const [editHazard, setEditHazard] = useState(DiagramStatus.NEW === mode);
  const [isEditHazardDisabled, setIsEditHazardDisabled] = useState(false);
  const [hazardTextAreaStyle, setHazardTextAreaStyle] = useState(hazardAndRiskDefaultStyle);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  useImperativeHandle(forwardRef, () => inputRef.current as HTMLTextAreaElement);

  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const canEditHazard =
    userWithPermissions &&
    userWithPermissions.permissions &&
    userWithPermissions.permissions.editRecord &&
    userWithPermissions.permissions.editRecord.main;

  useEffect(() => {
    if (inputRef.current?.focus && editHazard) {
      inputRef.current.focus();
    }
  }, [editHazard]);

  const handleRectangleClick = () => {
    if (canEditHazard) {
      setEditHazard(true);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      if (inputRef.current?.value === '') {
        inputRef.current.focus();
      } else {
        inputRef.current?.blur();
      }
    }
  };

  const handleAddHazard = () => {
    if (inputRef.current && inputRef.current.value && inputRef.current.value.trim() !== '') {
      setIsEditHazardDisabled(true);
      setHazardTextAreaStyle((oldStyle) => {
        oldStyle.borderStyle = 'solid';
        oldStyle.color = 'rgba(51, 51, 51, 0.5)';
        oldStyle.borderColor = palettes.criticalControl.rgb[5];

        return oldStyle;
      });
      dispatch(
        diagramActions.doAddHazard({
          payload: inputRef.current.value.trim(),
          onHazardDisabled: setIsEditHazardDisabled,
          onEditHazard: setEditHazard,
          onHazardTextAreaStyle: setHazardTextAreaStyle,
        })
      );
    }
  };

  const handleUpdateHazard = async () => {
    if (inputRef.current && inputRef.current.value && inputRef.current.value.trim() !== '') {
      setIsEditHazardDisabled(true);
      if (inputRef.current.value !== hazard) {
        setHazardTextAreaStyle((oldStyle) => {
          oldStyle.borderStyle = 'solid';
          oldStyle.color = 'rgba(51, 51, 51, 0.5)';
          oldStyle.borderColor = palettes.criticalControl.rgb[5];

          return oldStyle;
        });
        dispatch(
          diagramActions.doAddHazard({
            payload: inputRef.current.value.trim(),
            onHazardDisabled: setIsEditHazardDisabled,
            onEditHazard: setEditHazard,
            onHazardTextAreaStyle: setHazardTextAreaStyle,
          })
        );
      } else {
        setEditHazard(false);
        setIsEditHazardDisabled(false);
        setHazardTextAreaStyle((oldStyle) => {
          oldStyle.color = '#333';
          oldStyle.borderStyle = 'dashed';
          oldStyle.borderColor = palettes.criticalControl.primary;

          return oldStyle;
        });
      }
    } else {
      setEditHazard(false);
      setIsEditHazardDisabled(false);
    }
  };

  // resolve handler based on new/edit mode
  const handleOnBlur = DiagramStatus.NEW === mode ? handleAddHazard : handleUpdateHazard;

  // element
  if (isLoading) {
    return (
      <div
        style={{
          ...hazardTextAreaStyle,
          marginBottom: '8rem',
          resize: 'none',
          flexDirection: 'column',
        }}
      >
        <LoadingPreview message="Hazard will be displayed here" className="hazard-risk" />
      </div>
    );
  } else if (hazard && !editHazard) {
    return (
      <Rectangle
        text={hazard}
        backgroundColor={palettes.gray.primary}
        color={hazard ? '#ffffff' : '#333333'}
        borderColor={palettes.gray.primary}
        borderWidth="2px"
        width="150px"
        fontSize="20px"
        id="hazard"
        click={handleRectangleClick}
      />
    );
  } else {
    return (
      <textarea
        rows={2}
        id="hazard"
        defaultValue={hazard}
        ref={inputRef}
        disabled={isEditHazardDisabled}
        onKeyDown={handleOnKeyDown}
        placeholder="Click to add Hazard"
        onBlur={handleOnBlur}
        style={{
          ...hazardTextAreaStyle,
          marginBottom: '8rem',
          resize: 'none',
        }}
      />
    );
  }
};

export default forwardRef(Hazard);
