import React from 'react';
import LoadingControls from '../../Items/LoadingComponent/LoadingControls';

interface LoadingPreviewProps {
  message: string;
  className?: string;
}

const LoadingPreview = ({ message, className = '' }: LoadingPreviewProps) => {
  return (
    <div className={className}>
      <LoadingControls className="loading-size" />
      {message}
    </div>
  );
};

export default LoadingPreview;
