import './ActionModalContent.css';

interface ActionButton {
  onAction: React.MouseEventHandler;
  text: string;
}

interface ActionModalContentProps {
  actionButton: ActionButton;
  onClose: React.MouseEventHandler;
  text: string;
}

export default function ActionModalContent({ actionButton, onClose, text }: ActionModalContentProps) {
  return (
    <div className="modal-action-container">
      <div className="modal-action-text">{text}</div>
      <div className="modal-action-button-row">
        <button className="cancel-button" onClick={onClose}>
          Cancel
        </button>
        <button className="action-button" onClick={actionButton.onAction}>
          {actionButton.text}
        </button>
      </div>
    </div>
  );
}
