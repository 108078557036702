import React, { useEffect, useState, useRef, useCallback, CSSProperties } from 'react';
import { useAppDispatch, useAppSelector } from '../../../modules/hooks';
import diagramActions from '../../../modules/diagram/diagramActions';
import diagramSelectors from '../../../modules/diagram/diagramSelectors';
import RecordServiceJS from '../../../modules/diagram/diagramService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { palettes } from '../../../environment/environment';
import LoadingControls from '../../../components/Items/LoadingComponent/LoadingControls';
import { Loader } from '@myosh/odin-components';
import { BowtieModuleForm } from '../../../services/common-data-types';
import { BowtieBasicRecord, BowtieStateControlData, BowtieStateData } from '../../../services/bowtie-data-types';
import { LINE_DIRECTION } from '../../../components/diagram/shared/rectangle/rectangle';
import { RecordResult } from '../../../services/record-data-types';
import { debounce } from 'lodash';
import { useAIContext } from '../../../context/ai.context';
import './TextArea.css';

const recordService = new RecordServiceJS('test');

interface TextAreaProps {
  id: string;
  index: number;
  form: BowtieModuleForm;
  defaultValue: string;
  elementId?: number | string;
  parentId: number;
  line: BowtieBasicRecord;
  lineDirection: string;
  addElement: (payload: { id: string; value: string; parentId?: number; existingElement?: RecordResult }) => void;
  removeElement: (payload: { id: string; parentId: number }) => void;
  setElementToEdit: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const TextArea = ({
  id,
  index,
  form,
  defaultValue,
  elementId,
  parentId,
  line,
  lineDirection,
  addElement,
  removeElement,
  setElementToEdit,
}: TextAreaProps) => {
  const [cursorIndex, setCursorIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [showRecordList, setShowRecordList] = useState(true);

  const [textAreaStyle, setTextAreaStyle] = useState<CSSProperties>({
    color: palettes.text.primary,
    width: defaultValue ? '120px' : '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '0.6rem',
    zIndex: '3000',
    position: 'relative',
    outline: 'none',
    padding: '10px',
    height: '47px',
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderColor: palettes.criticalControl.primary,
    backgroundColor: palettes.lightWhite.primary,
  });

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const recordListContainerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const bowtieData: BowtieStateData = useAppSelector(diagramSelectors.selectBowtieData);
  const records = useAppSelector(diagramSelectors.selectFormRecords);
  const recordsLoading = useAppSelector(diagramSelectors.selectFormRecordsLoading);

  const isAIGenerated = useAIContext();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue;
      inputRef.current.focus();
    }

    return () => {
      dispatch(diagramActions.doResetFormRecords());
    };
  }, []);

  const getControlsFromBowtieData = useCallback(() => {
    let controls: Array<BowtieStateControlData> = [];

    if (bowtieData?.causes) {
      bowtieData.causes.forEach((cause) => {
        controls = [...controls, ...cause.preventativeControls] as Array<BowtieStateControlData>;
      });
    }

    if (bowtieData?.consequences) {
      bowtieData.consequences.forEach((consequence) => {
        controls = [...controls, ...consequence.mitigatingControls] as Array<BowtieStateControlData>;
      });
    }

    return controls.filter((control, index, controlArray) => {
      return controlArray.findIndex((c) => c.id === control.id) === index && typeof control.id === 'number';
    });
  }, [bowtieData]);

  const filterRelevantControls = (records: Array<RecordResult>) => {
    const diagramControls = getControlsFromBowtieData();

    return records
      ?.filter((record) => {
        const globalField = record.fields['Global'];

        return globalField || diagramControls.find((diagramControl) => diagramControl.id === record.id);
      })
      .map((record: RecordResult) => {
        const globalField = record.fields['Global'];

        return {
          ...record,
          global: Boolean(globalField),
        };
      })
      .sort((recordA: RecordResult, recordB: RecordResult) => {
        if (recordA.displayText && recordB.displayText) {
          return recordA.displayText.localeCompare(recordB.displayText);
        }
        return 0;
      });
  };

  const onAddControl = async () => {
    if (inputRef.current?.value && inputRef.current.value.length > 0) {
      const hasDefaultValue = Boolean(defaultValue); // indicates if this is an existing item
      if (elementId && typeof elementId !== 'number' && !hasDefaultValue) {
        setShowRecordList(false);
        addElement({
          id: elementId,
          value: inputRef.current.value,
          parentId,
        });
        setDisabled(true);
      } else {
        if (inputRef.current.value !== defaultValue) {
          setDisabled(true);
          setTextAreaStyle((oldstyle) => {
            oldstyle.borderStyle = 'solid';
            oldstyle.color = 'rgba(51, 51, 51, 0.5)';
            oldstyle.borderColor = palettes.criticalControl.rgb[5];

            return oldstyle;
          });

          if (isAIGenerated === true) {
            if (id === 'preventative_control') {
              dispatch(
                diagramActions.updatePreventativeControlInAIDiagram({
                  id: elementId,
                  value: inputRef.current.value,
                  parentId,
                })
              );
            } else {
              dispatch(
                diagramActions.updateMitigatingControlsInAIDiagram({
                  id: elementId,
                  value: inputRef.current.value,
                  parentId,
                })
              );
            }
          } else {
            if (id === 'preventative_control') {
              await recordService.updatePreventativeControl(bowtieData, line, inputRef.current?.value);
            } else {
              await recordService.updateMitigatingControl(bowtieData, line, inputRef.current?.value);
            }
          }

          setElementToEdit(undefined);
        } else {
          setDisabled(false);
          setElementToEdit(undefined);
        }
      }
    } else {
      if (elementId && typeof elementId !== 'number') {
        removeElement({ id: elementId, parentId });
      }
      setDisabled(false);
      setElementToEdit(undefined);
    }

    setTextAreaStyle((oldstyle) => {
      oldstyle.borderStyle = 'solid';
      oldstyle.color = 'rgba(51, 51, 51, 0.5)';
      oldstyle.borderColor = palettes.criticalControl.rgb[5];
      return oldstyle;
    });
  };

  const onAddExistingControl = (record: RecordResult) => {
    if (inputRef.current) {
      inputRef.current.value = record.fields['Control Name'] as string;
    }

    setShowRecordList(false);

    const recordControlName = record.fields['Control Name'] as string;

    addElement({
      id: String(elementId),
      value: recordControlName,
      parentId,
      existingElement: record,
    });

    setDisabled(true);
    setTextAreaStyle((oldstyle) => {
      oldstyle.borderStyle = 'solid';
      oldstyle.color = 'rgba(51, 51, 51, 0.5)';
      oldstyle.borderColor = palettes.criticalControl.rgb[5];
      return oldstyle;
    });
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !cursorIndex && !event.shiftKey) {
      event.preventDefault();
      inputRef.current?.blur();
    }
  };

  const handleOnChangeEvent =
    isAIGenerated === false
      ? debounce((event: React.ChangeEvent<HTMLTextAreaElement>) => {
          event.stopPropagation();
          event.preventDefault();
          if (event.target.value?.length >= 3) {
            dispatch(
              diagramActions.doFetchRecordsByFormId(form.id, {
                filter: `Control Name:like:${event.target.value}`,
              })
            );
          } else {
            dispatch(diagramActions.doResetFormRecords());
          }
        }, 800)
      : undefined;

  return (
    <div
      id={id}
      key={index}
      style={{
        position: 'relative',
        width: '200px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
      }}
      className={`rectangular-container`}
      onKeyDown={(event) => {
        event.stopPropagation();
        if (event.code === 'ArrowDown') {
          const downIndex = cursorIndex + 1 > filterRelevantControls(records).length ? cursorIndex : cursorIndex + 1;
          const recordItem = document.getElementById(`record-item-${downIndex - 1}`);
          recordItem && recordItem.scrollIntoView({ block: 'nearest', inline: 'nearest' });

          setCursorIndex(downIndex);
        }

        if (event.code === 'ArrowUp') {
          const upIndex = !cursorIndex ? 0 : cursorIndex - 1;
          const recordItem = document.getElementById(`record-item-${upIndex - 1}`);
          recordItem && recordItem.scrollIntoView({ block: 'nearest', inline: 'nearest' });

          setCursorIndex(upIndex);
        }

        if (event.code === 'Enter' && cursorIndex > 0) {
          onAddExistingControl(filterRelevantControls(records)[cursorIndex - 1]);
        }
      }}
    >
      <div>
        <textarea
          onKeyDown={handleKeyDownEvent}
          rows={3}
          cols={10}
          id={`${id + '_input'}`}
          ref={inputRef}
          disabled={disabled}
          onBlur={onAddControl}
          onChange={handleOnChangeEvent}
          style={{
            ...textAreaStyle,
            marginBottom: '8rem',
            resize: 'none',
          }}
        />
        {disabled && (
          <div
            className={`box-loading-controls 
            ${defaultValue ? 'r3' : 'r05'}`}
          >
            <LoadingControls />
          </div>
        )}
      </div>
      <div
        style={{
          background: palettes.criticalControl.primary,
          top: lineDirection === LINE_DIRECTION.BOTTOM ? '92%' : 0,
          bottom: lineDirection === LINE_DIRECTION.TOP ? '92%' : 0,
          height: '28px',
          width: '2px',
          position: 'absolute',
        }}
      ></div>
      {showRecordList && !defaultValue && (
        <div className="record-item-list-container" ref={recordListContainerRef}>
          {recordsLoading ? (
            <div style={{ color: palettes.criticalControl.primary }}>
              <Loader />
            </div>
          ) : filterRelevantControls(records)?.length > 0 ? (
            filterRelevantControls(records)?.map((record: RecordResult, index: number) => {
              return (
                <div
                  key={`record-item-${index}`}
                  className={`record-item ${cursorIndex - 1 === index ? 'record-item-active' : ''}`}
                  onClick={() => onAddExistingControl(record)}
                  id={`record-item-${index}`}
                >
                  <p>{record.fields['Control Name'] as string}</p>
                  {Boolean(record.fields['Global']) && (
                    <FontAwesomeIcon icon={faGlobe} color={palettes.criticalControl.primary} />
                  )}
                </div>
              );
            })
          ) : (
            '... press enter to create ...'
          )}
        </div>
      )}

      {showRecordList && !defaultValue && (
        <div
          className="text-area-add-button"
          onClick={async (event) => {
            event.preventDefault();
            event.stopPropagation();
            setShowRecordList(false);
            if (inputRef.current?.value) {
              addElement({
                id: String(elementId),
                value: inputRef.current.value,
                parentId,
              });
            } else {
              removeElement({ id: String(elementId), parentId });
            }
            setDisabled(true);
            setTextAreaStyle((oldstyle) => {
              oldstyle.borderStyle = 'solid';
              oldstyle.color = 'rgba(51, 51, 51, 0.5)';
              oldstyle.borderColor = palettes.criticalControl.rgb[5];
              return oldstyle;
            });
          }}
        ></div>
      )}
    </div>
  );
};

export default TextArea;
