import React from 'react';
import Controls from '../../../../views/shared/components/controls.component';
import LoadingPreview from '../loading-preview';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import userSelectors from '../../../../modules/user/userSelectors';
import { palettes } from '../../../../environment/environment';
import diagramActions from '../../../../modules/diagram/diagramActions';

interface CausesProps {
  isLoading: boolean;
}

const Causes = ({ isLoading }: CausesProps) => {
  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const canCreateCauses =
    userWithPermissions &&
    userWithPermissions.permissions &&
    userWithPermissions.permissions.createRecord &&
    userWithPermissions.permissions.createRecord.causes;

  const addCauseInput = () => {
    dispatch(diagramActions.doAddCauseInput());
  };

  return (
    <Controls
      title={'Causes'}
      id="causes-container"
      content={
        isLoading ? (
          <LoadingPreview message="Causes will be displayed here" />
        ) : canCreateCauses ? (
          'Add causes in this box'
        ) : (
          'Causes will be displayed here'
        )
      }
      add={isLoading ? null : canCreateCauses}
      height={'75vh'}
      width={'10vw'}
      color={palettes.criticalControl.primary}
      onAdd={addCauseInput}
    />
  );
};

export default Causes;
